import React from 'react';
import PopularTours from "../screens/search/popular-tours/PopularTours";
import { useTranslation } from "react-i18next";
import { ApiData } from "../../api/apiData/api.data";
import { useQueries } from "react-query";
import { SearchDirectionService } from "../../services/search-direction/search-direction.service";

import './../../../src/components/screens/Home/popular-destinations/PopularDestinations.scss'

const SearchHome = ({ setTimeData, timeData }) => {
    const { t } = useTranslation()
    const countryCode = ApiData.countryCode
    const subtitles = [
        {
            subtitle: t('subtitleEG')
        },
        {
            subtitle: t('subtitleGR')
        },
        {
            subtitle: t('subtitleTR')
        },
        {
            subtitle: t('subtitleCN')
        },

        // {
        //     subtitle: t('subtitleME')
        // },
    ]

    const getPopularTour = useQueries([
        {
            queryKey: ['post', 1],
            queryFn: () =>
                SearchDirectionService.getSearchDirection({
                    countryCode: countryCode[0],
                    townFrom: timeData.townFrom || 'lv'
                })
        },
        {
            queryKey: ['post2', 2],
            queryFn: () =>
                SearchDirectionService.getSearchDirection({
                    countryCode: countryCode[1],
                    townFrom: timeData.townFrom || 'lv'
                })
        },
        {
            queryKey: ['post3', 3],
            queryFn: () =>
                SearchDirectionService.getSearchDirection({
                    countryCode: countryCode[2],
                    townFrom: timeData.townFrom || 'lv'
                })
        },
        {
            queryKey: ['post4', 4],
            queryFn: () =>
                SearchDirectionService.getSearchDirection({
                    countryCode: countryCode[3],
                    townFrom: timeData.townFrom || 'lv'
                })
        },
        // {
        //     queryKey: ['post5', 5],
        //     queryFn: () =>
        //         SearchDirectionService.getSearchDirection({
        //             countryCode: countryCode[4],
        //             townFrom: timeData.townFrom || 'lv'
        //         })
        // }
    ])
    return (
        <div className='container-xxl popular-destinations'>
            <div className='row'>
                <div className='col-12 col-md-10'>
                    <h3 className='block_title'>{t('popular_destionations')}</h3>
                    <p className='block_description'>
                        {t('these_are_popular_destinations_over_the_past_month')}
                    </p>
                </div>
            </div>
            <PopularTours
                setTimeData={setTimeData}
                timeData={timeData}
                getPopularTour={getPopularTour}
                subtitles={subtitles}
            />
        </div>
    );
};

export default SearchHome;