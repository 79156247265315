import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import style from './Hotel.module.scss'
import { useTranslation } from 'react-i18next'

const ReviewsLinkRating: FC<any> = ({
	currentRating,
	googleReviewsUrl,
	ta_url,
	hotelReviews,
	hotelReviewsGoogle,
	forMob,
	scrollToElement
}) => {
	const { t, i18n } = useTranslation()
	return (
		<>
			<div
				onClick={scrollToElement}
				className={
					style.wrapper_btn_link_all +
					' ' +
					(forMob ? style.wrapper_btn_link_mob : '')
				}
			>
				<div className={style.reviews_description_star}>
					<div className='d-flex align-items-center'>
						<span className={style.reviews_description_star_value}>
							{currentRating}
						</span>
						<div
							className={style.reviews_description_img_star}
						></div>
					</div>
					{hotelReviews?.length + hotelReviewsGoogle?.length}{' '}
					{t('atsauksmes')}
				</div>

				{googleReviewsUrl?.length > 0 && (
					<Link
						className={style.btn_link_all}
						to={googleReviewsUrl}
						target='_blank'
					>
						Google
					</Link>
				)}

				{ta_url?.length > 0 && (
					<Link
						className={style.btn_link_all}
						to={ta_url}
						target='_blank'
					>
						Tripadvisor
					</Link>
				)}
			</div>
		</>
	)
}

export default ReviewsLinkRating
