import React, { FC, useEffect, useState } from 'react'
import style from './TableList.module.scss'
import tourSvg from '@/assets/images/tourSvg.svg'
import Button from '@/components/ui/button/Button'
import hotelSvg from '@/assets/images/trip/hotel.svg'
import mealSvg from '@/assets/images/trip/meal.svg'
import tripSvg from '@/assets/images/trip/trip.svg'
import { useTranslation } from 'react-i18next'
import { ChangeDate, ChangeMont } from '@/utils/change-date/ChangeDate'
import loaderSvg from '@/assets/images/spinner_blue.svg'
import FlightInfo from './FlightInfo'

const replaceDateString = (date: string) => {
	let year = date.slice(0, 4)
	let month = date.slice(5, 7)
	let day = date.slice(8, 10)
	return day + '.' + month + '.' + year
}

const TableList: FC<any> = ({
	offerList,
	sendOrder,
	hotelEnabled,
	getHotel,
	date
}) => {
	const [isOpenFlight, setOpenFlight] = React.useState({})
	const { t, i18n } = useTranslation()
	const [errorMessage, setErrorMessage] = useState(t('No_tours_found'))
	const currentLanguage = i18n.language
	useEffect(() => {
		offerList?.length == 0 && date == null
			? setErrorMessage(t('Select_date'))
			: setErrorMessage(t('No_tours_found'))
	}, [date, offerList])

	function formatShortDate(date: string) {
		return ChangeDate(date).slice(0, -5)
	}
	return (
		<div className={`${style.tableList}`}>
			<FlightInfo info={isOpenFlight} onClose={setOpenFlight} />
			<ul className={style.externalUl}>
				<li>
					<ul className={style.insideUl}>
						<li>{t('departure_date')}</li>
						<li>{t('flight_info_full')}</li>
						<li>{t('room_type')}</li>
						<li>{t('meal')} </li>
						<li>{t('price_for_all')}</li>
						<li></li>
					</ul>
				</li>
				{hotelEnabled ? (
					<div className={style.offerListLoading}>
						{t('search_for_suitable_offers')}
						<img src={loaderSvg} alt='' />
					</div>
				) : offerList.length !== 0 ? (
					<>
						{offerList.map((el: any, key: any) => (
							<li key={key}>
								<ul className={style.insideUlList}>
									<li className='leading-6 '>
										<div className='flex justify-center'>
											<img
												src={tourSvg}
												alt='tour'
												className='mr-1'
											/>
											<div>
												<p>{ChangeDate(el.checkIn)}</p>
												<p className='text-left'>
													{formatShortDate(
														el.checkOut
													)}
												</p>
											</div>
										</div>
										<p className='text-center mr-8 text-[#BCBCBC] text-[1.1rem]'>
											{' '}
											{el.nights} {t('nights_reister')}
										</p>
									</li>
									<li
										onClick={() =>
											setOpenFlight({
												data: el,
												open: true
											})
										}
										className='cursor-pointer underline'
									>
										{t('flight_info')}
									</li>
									<li className='flex justify-center'>
										<img src={hotelSvg} className='mr-3' />
										{el.room}
									</li>
									<li className='flex justify-center'>
										<img src={mealSvg} className='mr-3' />
										{t(el.meal.replace(/\s+/g, ''))}
									</li>
									<li className={style.order}>
										{el.price} €
									</li>
									<li>
										<Button
											onClick={() => {
												sendOrder({
													checkIn: el.checkIn,
													checkOut: el.checkOut,
													hotelName: el.hotelName,
													meal: el.meal,
													price: el.price,
													room: el.room,
													partner: el.partner
												})
											}}
										>
											{t('order')}
										</Button>
									</li>
								</ul>
							</li>
						))}
					</>
				) : (
					<div className={style.offerListLoading}>{errorMessage}</div>
				)}
			</ul>

			<ul className={style.externalUl2}>
				{hotelEnabled ? (
					<div className={style.offerListLoading}>
						{t('search_for_suitable_offers')}
					</div>
				) : offerList.length !== 0 ? (
					<>
						{offerList.map((el: any, key: any) => (
							<li key={key}>
								<ul className={style.insideUlList2}>
									<li className='leading-6 '>
										<div className='flex justify-start'>
											<img
												src={tourSvg}
												alt='tour'
												className='mr-1'
											/>
											<div>
												<p>
													{replaceDateString(
														el.checkIn
													)}
												</p>
												<p className='text-left'>
													{formatShortDate(
														el.checkOut
													)}
												</p>
											</div>
										</div>

										<p className='ml-3 text-[#BCBCBC] text-[1.1rem]'>
											{' '}
											{el.nights} {t('nights_reister')}
										</p>
										<div
											className={style.trip}
											onClick={() =>
												setOpenFlight({
													data: el,
													open: true
												})
											}
										>
											<img src={tripSvg} alt='trip' />
											<p>
												{currentLanguage == 'lv'
													? getHotel.location_lv
													: getHotel.location_ru}
											</p>
										</div>
									</li>
									<li className={style.order}>
										{el.price} €
										<Button
											onClick={() =>
												sendOrder({
													checkIn: el.checkIn,
													checkOut: el.checkOut,
													hotelName: el.hotelName,
													meal: el.meal,
													price: el.price,
													room: el.room,
													partner: el.partner
												})
											}
										>
											{t('order')}
										</Button>
									</li>
									<li className='flex justify-center'>
										<img src={mealSvg} className='mr-2' />
										{t(el.meal.replace(/\s+/g, ''))}
									</li>
									<li className='flex justify-center'>
										<img src={hotelSvg} className='mr-2' />
										{el.room}
									</li>
								</ul>
							</li>
						))}
					</>
				) : (
					<div className={style.offerListLoading}>{errorMessage}</div>
				)}
			</ul>
		</div>
	)
}
export default TableList
