import React, { useRef } from 'react'
import './ReviewSlide.scss'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { reviews } from '@/assets/data/reviews'
import ReviewSlide from './ReviewSlide'
import { useTranslation } from 'react-i18next'

const ReviewSlider = () => {
	const ref = useRef<any>(null)
	const { t } = useTranslation()

	return (
		<div className='review-component'>
			<div className='carusel_wrap carusel_wrap3 mt_block feedback_wrapper'>
				<div className='container-xxl'>
					<div className='otz'>
						<div className=''>
							<div className='flex justify-between items-center swordStyleRST'>
								<h3 className='block_title'>
									{t('feedback_from_our_customers')}
								</h3>
							</div>
							<p className='block_description'>
								{t('we_glad_feedback')}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className='review-swiper-wrapper'>
				<div className='container-xxl carousel-wrapper'>
					<Swiper
						className='swiper_home_rev'
						ref={ref}
						loop={true}
						modules={[Pagination]}
						spaceBetween={10}
						pagination={{
							clickable: true,
							dynamicBullets: true,
							dynamicMainBullets: 4
						}}
						breakpoints={{
							576: {
								slidesPerView: 1
							},
							768: {
								slidesPerView: 2
							},
							1000: {
								slidesPerView: 2
							}
						}}
					>
						{reviews.map((review, index) => (
							<SwiperSlide key={index}>
								<ReviewSlide
									description={review.description}
									name={review.name}
									date={review.date}
									image={review.image}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	)
}

export default ReviewSlider
