import axios from "axios"

const  getGoogleRating=(data:any,setGoogleRating:any)=>{
    const apiUrl =
    'https://api.mandarina.lv/api/google-reviews-rating?hotelCode=' +
    data.hotelCode
const config = {
    withCredentials: false
}
axios
    .post(apiUrl, '', config)
    .then(response => {
        setGoogleRating(response.data)
    })
    .catch(error => {
        console.error('Ошибка при выполнении запроса:', error)
    })
}

export default getGoogleRating;