import AboutTable from '@/templates/about-table/AboutTable'
import React, { FC } from 'react'
import Footer from '../footer/Footer'
import Header from '../Home/header/Header'
import MailingComp from '../Home/mailing-comp/MailingComp'
import style from './Terms.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const TermsPage: FC = () => {
	return (
		<div className=''>
			<div className='bg-gray-wrapper'>
				<Header />
			</div>
			<div className='row tab_row'>
				<div
					className='col-12'
					style={{ paddingRight: '10.5px', paddingLeft: '10.5px' }}
				>
					<div className={style.content}>
						<h1>Pakalpojuma noteikumi</h1>
						<div className={style.table}>
							<AboutTable>
								<h2>
									<p>1</p>
									I. VISPĀRĒJĀ DAĻA
								</h2>
								<div className={style.content_tab}>
									<ol>
										<li>
											Mandarina Travel, SIA “iSYTC”,
											reģistrēts Latvijas Republikas
											Uzņēmumu reģistrā ar reģistrācijas
											Nr. 40103282473, juridiskā adrese
											Kartinas dambis 24b-12, Rīga,
											LV-1045, Latvija, (turpmāk tekstā –
											Mandarina.lv) veic tūrisma aģentūras
											funkcijas, strādājot kā piegādātājs
											starp klientiem un tūrisma
											operatoriem vai citiem tūrisma
											pakalpojumu sniedzējiem un šie
											noteikumi nosaka kārtību, kādā
											Klients izmanto Mandarina.lv
											pakalpojumus.
										</li>
										<li>
											Apmeklētājs – jebkura persona, kas
											apmeklē Mandarina.lv mājas lapu{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											.
										</li>
										<li>
											Klients – persona, kas veikusi
											tūrisma pakalpojuma rezervāciju
											mājas lapā{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											.
										</li>
										<li>
											Izmantojot Mandarina.lv
											pakalpojumus, ikviena apmeklētāja
											pienākums ir iepazīties ar šiem
											lietošanas noteikumiem un ievērot
											tos. Izmantojot Mandarina.lv
											pakalpojumus, Jūs piekrītat šiem
											noteikumiem. Jūs nevarat izmantot
											Mandarina.lv pakalpojumus, ja
											nepiekrītat kādai šo noteikumu
											daļai.
										</li>
										<li>
											Visu tūrisma pakalpojumu pieejamība
											Mandarina.lv ir atkarīga no tūrisma
											operatoriem vai tūrisma pakalpojumu
											sniedzējiem.
										</li>
										<li>
											Mandarina.lv pieejamie pakalpojumi
											ir ceļojumi, kurus piedāvā un
											nodrošina tūrisma operatori vai
											tūrisma pakalpojumu sniedzēji,
											viesnīcas, aviokompānijas,
											apdrošināšanas kompānijas un
											autonomas.
										</li>
										<li>
											Mandarina.lv par tūrisma operatoru
											un tūrisma pakalpojumu sniedzēju
											nodrošinātajiem tūrisma
											pakalpojumiem atbild tikai kā
											starpnieks savas kompetences
											ietvaros saskaņā ar noslēgtajiem
											sadarbības līgumiem tajos noteiktajā
											apmērā. Mandarina.lv garantē savu
											sniegto pakalpojumu kvalitāti
											saskaņā ar Latvijas Republikas spēkā
											esošo normatīvo aktu prasībām,
											ievērojot ar tūrisma operatoriem vai
											citiem tūrisma pakalpojumu
											sniedzējiem noslēgtos līgumus
										</li>
										<li>
											Pasūtījums ir jebkurš tūrisma
											pakalpojumu pasūtījums, ja Klients
											ir veicis rezervāciju, izmantojot
											Mandarina.lv, veicot apmaksu par
											pasūtījumu un saņemot pasūtījuma
											apstiprinājumu uz norādīto e-pastu.
										</li>
										<li>
											Pēc rezervācijas veikšanas
											Mandarina.lv, kad attiecīgais
											ceļojumu pakalpojums ir apstiprināts
											un apmaksāts, līgumsaistības
											pastāvēs starp Klientu un attiecīgo
											Pakalpojumu sniedzēju. Mandarina.lv
											šajās līgumattiecībās var būt
											iesaistīts tikai kā pilnvarotā
											persona.
										</li>
										<li>
											Klients piekrīt, ka ikviens līgums
											vai rēķins, ko Mandarina.lv ir
											sagatavojusi Klientam elektroniski,
											ir derīgs un spēkā esošs bez
											paraksta. Pēc Mandarina.lv
											pieprasījuma (vajadzības gadījumā)
											Klients apņemas parakstīt
											analoģiskus dokumentus papīra
											formātā. Mandarina.lv ir tiesības
											sazināties ar Klientu pa viņa
											norādīto e-pastu vai tālruni.
										</li>
										<li>
											a ceļojumā piedalās nepilngadīgas
											personas, Klienta pienākums ir
											pārliecināties, līdz cik gadu
											vecumam konkrētajā viesnīcā
											nepilngadīga persona tiek uzskatīta
											par bērnu un saskaņā ar šo
											informāciju arī norādīt nepilngadīgo
											personu pie bērniem vai pie
											pieaugušajiem. Šī informācija ir
											atrodama pie informācijas par
											viesnīcu konkrētajā ceļojuma
											piedāvājumā
										</li>
										<li>
											Piekrītot šiem noteikumiem,
											apliecināt, ka esat iepazinies un
											piekrītat Mandarina.lv{' '}
											<Link
												to={'/privacy-policy'}
												className={style.link}
											>
												Privātuma politika
											</Link>
											.
										</li>
										<li>
											Piekrītot šiem noteikumiem,
											apliecināt, ka esat iepazinies un
											piekrītat Mandarina.lv Atgriešanas
											noteikumi.
										</li>
									</ol>
								</div>
								<h2>
									<p>2</p>
									II. TŪRISMA PAKALPOJUMU SNIEDZĒJI UN TO
									NOTEIKUMI
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv piedāvātos ceļojumus un
											tūrisma pakalpojumus nodrošina
											tūrisma operatori un tūrisma
											pakalpojumu sniedzēji. Visi tūrisma
											operatori, kas nodrošina piedāvātos
											kompleksos tūrisma pakalpojumus, ir
											apdrošināti saskaņā ar Latvijas
											Republikā spēkā esošajiem
											normatīvajiem aktiem.
										</li>
										<li>
											Papildus Mandarina.lv noteikumiem,
											Klientam saistoši ir arī katra
											tūrisma operatora un tūrisma
											pakalpojumu sniedzēja noteikumi.
											Lietojot Mandarina.lv, Klients
											piekrīt tūrisma pakalpojumu
											sniedzēju noteikumiem un tos ievēro.
										</li>
										<li>
											Klienta pienākums ir precīzi ievērot
											tūrisma operatoru un tūrisma
											pakalpojumu sniedzēju prasības
											attiecībā uz pakalpojumu
											reģistrēšanās laikiem, rezervācijas
											apmaksu, rezervācijas apstiprināšanu
											u.c. noteikumiem.
										</li>
										<li>
											Izmantojot Mandarina.lv, Klientam
											būs pieejama informācija par tūrisma
											operatoru un tūrisma pakalpojumu
											sniedzēju mājaslapām, kurās ir
											pieejami noteikumi, kas attiecās uz
											to sniegtajiem pakalpojumiem.
											Mandarina.lv iesaka izlasīt šos
											noteikumus un sazināties ar
											Mandarina.lv vai attiecīgo
											pakalpojumu sniedzēju, lai uzzinātu
											detalizētu informāciju par spēkā
											esošajiem noteikumiem attiecībā uz
											autobusu vai avio pasažieru
											pārvadājumiem, apmaksas veikšanu,
											saistību nepildīšanu, atbildību,
											rezervācijas atcelšanu, izmaiņām
											veiktajos pasūtījumos, kompensācijām
											un uz jebkādiem citiem
											ierobežojumiem
										</li>
									</ol>
								</div>

								<h2>
									<p>3</p>
									III. PASŪTĪJUMA ATCELŠANA VAI MAINĪŠANA, KO
									VEIC KLIENTS
								</h2>
								<div>
									<ol>
										<li>
											Katra pasūtījuma maiņai vai
											atcelšanai ir zināmi ierobežojumi un
											noteikumi saskaņā ar konkrētā
											tūrisma operatora līguma
											nosacījumiem, kurus Klients akceptē
											rezervācijas veikšanas brīdī.
										</li>
										<li>
											Dažos gadījumos Mandarina.lv vai
											tūrisma pakalpojumu sniedzējs nevar
											atcelt tūrisma pakalpojumu
											rezervāciju vai veikt tajā izmaiņas
											pirms rakstiska pieprasījuma
											saņemšanas no Klienta, ar kuru
											Klients piekrīt jebkādām papildus un
											saistītajām izmaksām.
										</li>
										<li>
											Dažu pakalpojumu atcelšana vai
											izmaiņu veikšana tajos ir
											neiespējama.
										</li>
										<li>
											Naudas atgriešana par anulētu
											tūrisma pakalpojumu tiks veikta tādā
											maksājuma formā, kāda izmantota
											veicot pasūtījumu. Nauda tiks
											atgriezta personai, kas veikusi
											sākotnējo maksājumu uz to norēķinu
											kontu, no kura tika veikts
											maksājums.
										</li>
									</ol>
								</div>
								<h2>
									<p>4</p>
									IV. APMAKSA
								</h2>
								<div>
									<ol>
										<li>
											Klienta pienākums ir veikt samaksu
											par tūrisma pakalpojumu saskaņā ar
											tūrisma pakalpojuma piedāvājumā
											norādīto maksāšanas kārtību.
											Iespējamie apmaksas veidi var būt
											atšķirīgi atkarībā no izvēlētā
											tūrisma pakalpojuma veida un
											pakalpojuma sniedzēja, kas nodrošina
											šo pakalpojumu.
										</li>
										<li>
											Mandarina.lv un tūrisma pakalpojumu
											sniedzējs neizsniedz biļetes,
											apstiprinājumus, vaučerus vai citus
											ceļojuma dokumentus, ja Klients nav
											veicis pilnu samaksu par
											pakalpojumu.
										</li>
										<li>
											Pirms biļešu izsniegšanas
											Mandarina.lv patur tiesības veikt
											klienta identifikāciju saskaņā ar
											spēkā esošajiem normatīvajiem
											aktiem.
										</li>
									</ol>
								</div>
								<h2>
									<p>5</p>
									V. REZERVĀCIJAS APSTIPRINĀJUMS
								</h2>
								<div>
									<ol>
										<li>
											Veicot pasūtījumu, Klients pārbauda
											rezervācijas apstiprinājumā
											ierakstītā vārda un uzvārda
											atbilstību pases datiem.
										</li>
										<li>
											Rezervācijas apstiprinājumi, vaučeri
											un citi dokumenti tiek piegādāti,
											nosūtot tos uz Klienta norādīto
											e-pastu vienas darba dienas laikā no
											brīža, kad ir veikta pilna tūrisma
											pakalpojuma apmaksa.
										</li>
										<li>
											Mandarina.lv paļaujas uz Klienta
											sniegtās informācijas precizitāti un
											neuzņemas atbildību par biļešu
											zaudējumu, ja nav uzrādīta precīza
											e-pasta adrese vai tā saņemšana ir
											bloķēta e-pasta uzstādījumu dēļ.
										</li>
									</ol>
								</div>
								<h2>
									<p>6</p>
									VI. MANDARINA.LV INFORMĀCIJA UN NOTEIKUMI
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv nodrošina attiecīgās
											Pakalpojumu sniedzēja datubāzes un
											rezervēšanas sistēmas pieejamību
											Klientam, kā arī nepieciešamības
											gadījumā veic papildus pasūtījuma
											apstrādi
										</li>
										<li>
											Mandarina.lv jebkurā laikā var veikt
											izmaiņas tā saturā, tai skaitā
											informācijā par jebkuru piegādātāju,
											programmu, kā arī datubāzēs
										</li>
										<li>
											Mandarina.lv pieļauj iespēju, ka
											mājas lapā esošā informācija (tai
											skaitā, cenas, apraksti, datumi) var
											būt kļūdaina vai nepilnīga, taču
											Mandarina.lv pēc iespējas centīsies
											labot visas kļūdas vai nepilnības,
											tiklīdz tās tiks pamanītas vai
											Mandarina.lv par to tiks informēts.
										</li>
										<li>
											Mandarina.lv, kā starpnieks, publicē
											to informāciju mājas lapā{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											, kuru ir ievietojis un
											aktualizējies tūrisma pakalpojumu
											sniedzējs. Informācija par
											viesnīcām, to piedāvātajiem
											pakalpojumiem var būt neprecīza vai
											novecojusi, ja attiecīgais
											pakalpojuma sniedzējs to nav
											aktualizējis. Mandarina.lv nenes
											atbildību par šādām nesakritībām.
										</li>
										<li>
											Klients pats ir atbildīgs par datu
											pareizību un informācijas
											atbilstību, īpaši attiecībā uz
											pasēm, vīzām un vakcinācijas
											prasībām, un apņemas sazināties ar
											attiecīgo tūrisma pakalpojumu
											sniedzēju, galamērķa valsts
											vēstniecību vai tūrisma informācijas
											centru.
										</li>
										<li>
											Mandarina.lv mājas lapā var būt
											hipersaites uz citām mājas lapām,
											kas pieder un ko pārvalda trešās
											personas. Mandarina.lv nekontrolē un
											neatbild par šādām trešo personu
											mājas lapām, un Mandarina.lv
											neuzņemas atbildību par tajās
											atrodamās informācijas precizitāti,
											pilnību un kvalitāti. Visus
											jautājumus, kas attiecas uz ārējās
											saitēs atrodamo informāciju,
											jāpieprasa attiecīgās mājas lapas
											administratoram vai tīmekļa pārzinim
										</li>
										<li>
											Attiecībā uz starptautiskajiem avio,
											jūras, sauszemes un dzelzceļa
											ceļojumiem, Mandarina.lv atbildību
											ierobežo attiecīgo starptautisko
											konvenciju noteikumi.
										</li>
										<li>
											Mandarina.lv neuzņemas atbildību par
											gadījumiem, kad pasūtījuma daļas vai
											visa pasūtījuma neizpilde, ir
											radusies tādu apstākļu rezultātā,
											kas nav bijusi saistīta ar mājas
											lapas{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											, aģentu vai tūrisma pakalpojumu
											sniedzēju vainu
										</li>
										<li>
											Mandarina.lv neuzņemas atbildību par
											jebkādiem tiešiem vai netiešiem
											zaudējumiem, civiltiesību
											pārkāpumiem vai citiem nodarījumiem,
											kas radušies kā sekas, izmantojot
											kādu no ceļojumu pakalpojumiem, kas
											rezervēti vai iegādāti caur
											Mandarina.lv
										</li>
										<li>
											Gadījumā, ja ceļojuma cenā nav
											iekļauta ceļojumu apdrošināšana,
											Klients apņemas pastāvīgi apdrošināt
											sevi un savus līdzbraucējus visam
											ceļojuma periodam. Klients uzņemas
											pilnu atbildību par iespējamām
											sekām, ja nebūs iegādājies ceļojuma
											apdrošināšanu.
										</li>
									</ol>
								</div>
								<h2>
									<p>7</p>
									VII. PASES, VĪZAS UN VESELĪBAS APDROŠINĀŠANA
								</h2>
								<div>
									<ol>
										<li>
											Dažas valstis izvirza imigrācijas
											prasību, ka ceļotāja pasei ir jābūt
											derīgai noteiktu periodu pēc
											iebraukšanas valstī. Parasti šis
											termiņš ir 3 vai 6 mēneši. Katram
											Klientam jāpārliecinās un Klients
											pats ir atbildīgs par atbilstošu
											pases derīguma termiņu.
											Pārliecināties par ieceļošanas
											prasībām konkrētajā valstī var
											izmantojot, piemēram, LR Ārlietu
											ministrijas mājas lapu
											www.mfa.gov.lv.
										</li>
										<li>
											Bērniem, ceļojot uz ārvalstīm, ir
											nepieciešama pase.
										</li>
										<li>
											Pasē norādītajam vārdam ir jāsakrīt
											ar biļetē ierakstīto vārdu, pretējā
											gadījumā biļete un apdrošināšana nav
											derīga. Ja kāds no pieteiktajiem
											ceļojuma dalībniekiem pēc
											rezervācijas veikšanas maina
											uzvārdu, piemēram, apprecoties,
											pasūtījuma veicējam nekavējoties
											jāsniedz atbilstoša informācija, lai
											veiktu nepieciešamās izmaiņas
											ceļojuma dokumentos. Šādu izmaiņu
											veikšana, atkarībā no tūrisma
											pakalpojuma sniedzēja, var būt
											maksas pakalpojums.
										</li>
										<li>
											Klientam ļoti ieteicams iegādāties
											ceļojuma apdrošināšanu, jo var
											rasties apstākļi, par kuriem
											atbildību nevar uzņemties ne
											Mandarina.lv, ne tūrisma pakalpojumu
											sniedzējs (piemēram, reisu kavēšanās
											vai anulēšana u.tml).
										</li>
										<li>
											Klients pats ir atbildīgs par sava
											ceļojuma apdrošināšanu, lai
											izvairītos no apstākļiem, par kuriem
											atbildību neuzņemas ne Mandarina.lv,
											ne tūrisma pakalpojumu sniedzējs
											(reisu kavēšanās, anulēšana u.c.).
										</li>
										<li>
											Klients ir atbildīgs par veselības
											prasību pārbaudi un izpildi.
										</li>
										<li>
											Paredzētā pakalpojuma izpildes
											laiks, t.i. - izlidošanas vai
											izbraukšanas laiki var mainīties
											(kavēties) un tas ir ārpus
											Mandarina.lv kontroles. Klientam ir
											pienākums veikt nepieciešamās
											darbības, lai pārliecinātos par
											pakalpojuma izpildes laikiem, tai
											skaitā, bet ne tikai, attiecībā uz
											izlidošanu vai izbraukšanu vienu
											dienu pirms noteiktā pakalpojuma
											saņemšanas dienas.
										</li>
									</ol>
								</div>
								<h2>
									<p>8</p>
									VIII. NEPĀRVARAMAS VARAS APSTĀKĻI
								</h2>
								<div>
									<ol>
										<li>
											Nepārvaramas varas jēdziens bez
											ierobežojumiem ietver intervenci,
											karus, civilos nemierus,
											nolaupīšanas, ugunsgrēkus, plūdus,
											negadījumus, vētras, teroristu
											uzbrukumus, streikus, lokautus,
											valsts normatīvo aktu izmaiņas
											attiecībā uz iebraukšanu/
											izbraukšanu, ārvalstu
											pārstāvniecību, robežsardzes/muitas,
											transporta dienestu un drošības
											institūciju darbību u.c. darbībām,
											kas nepārvarami ietekmē Mandarina.lv
											vai tūrisma pakalpojumu sniedzēju
											pakalpojumu nodrošināšanu.
										</li>
										<li>
											Mandarina.lv neuzņemas atbildību par
											jebkādu pakalpojumu neizpildi, ja tā
											radusies nepārvaramas varas ietekmē
										</li>
									</ol>
								</div>
								<h2>
									<p>9</p>
									IX. JAUTĀJUMI UN SŪDZĪBAS
								</h2>
								<div>
									<ol>
										<li>
											Pirms došanās ceļojumā jebkuri
											papildu jautājumi vai sūdzības, kas
											attiecas uz pasūtījumu, risināmi,
											zvanot uz Mandarina.lv palīdzības
											tālruni{' '}
											<a
												href='tel:+37126345147'
												className={style.link}
											>
												+371&nbsp;26345147
											</a>
											. Ja ceļojuma laikā Klientam rodas
											sūdzības, kas attiecas uz tūrisma
											pakalpojumu sniedzēju un/vai
											viesnīcu, pirms atgriešanās likumā
											noteiktā kārtībā jāiesniedz savu
											sūdzību attiecīgajam tūrisma
											pakalpojumu sniedzējam un/vai
											viesnīcai.
										</li>
										<li>
											Ja ceļojuma laikā radušies kādi
											jautājumi vai sūdzības, kas attiecas
											uz pasūtījumu, Klientam jāsazinās ar
											Mandarina.lv klientu apkalpošanas
											dienestu ne vēlāk kā 30 dienu laikā
											pēc atgriešanās, norādot pasūtījuma
											numuru un visu nepieciešamo
											informāciju. Ja Mandarina.lv vai
											kādam no tūrisma pakalpojumu
											sniedzējiem būs nepieciešama
											papildus informācija, Klients to
											iesniedz rakstiski.
										</li>
									</ol>
								</div>
								<h2>
									<p>10</p>
									X. IZMAIŅAS MANDARINA.LV NOTEIKUMOS
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv patur tiesības veikt
											izmaiņas šajos lietošanas
											noteikumos, Klientus iepriekš
											nebrīdinot.
										</li>
										<li>
											Aktuālā un spēkā esošā noteikumu
											versija ir tā, kas konkrētajā brīdī
											ir skatāma mājas lapā{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
										</li>
										<li>
											Turpmāka Mandarina.lv lietošana, pēc
											izmaiņu veikšanas noteikumos,
											apstiprina Jūsu piekrišanu veiktajām
											izmaiņām.
										</li>
									</ol>
								</div>
								<h2>
									<p>11</p>
									XI. MANDARINA.LV DATU AIZSARDZĪBA UN DROŠĪBA
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv ir veikusi visus
											nepieciešamos pasākumus, lai
											nodrošinātu personas datu drošību un
											nepieļautu to nepamatotu nokļūšanu
											trešo personu rīcībā. Mandarina.lv
											ievēro personas datu aizsardzību
											saskaņā ar Eiropas Savienības
											Vispārīgās datu aizsardzības regulu
											Nr.2016/679 un Latvijas Republikas
											spēkā esošajiem tiesību aktiem
										</li>
										<li>
											Rezervējot tūrisma pakalpojumus
											Mandarina.lv, Klients piekrīt, ka
											iesniegtos datus apstrādās arī
											trešās puses, t.i., tūrisma
											pakalpojumu sniedzēji. Mandarina.lv
											neatbild par personas datu drošību,
											kad tie ir nodoti tūrisma operatoram
											un tūrisma pakalpojumu sniedzējam.
										</li>
										<li>
											Mandarina.lv patur tiesības bez
											iepriekšēja brīdinājuma jebkurā
											laikā liegt pieeju mājas lapai, ja
											Klients izmanto šo interneta lapu
											pretēji tās lietošanas noteikumiem.
										</li>
									</ol>
								</div>
							</AboutTable>
						</div>
					</div>
				</div>
			</div>
			<MailingComp />
			<Footer />
		</div>
	)
}

export default TermsPage
