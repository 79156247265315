import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import style from './ContactForm.module.scss'
const ContactFormDop: FC<any> = ({
	index,
	value,
	setValue,
	error,
	resetError
}) => {
	const { t } = useTranslation()
	return (
		<div className={style.form} onClick={() => resetError()}>
			<h2>
				{t('contact_information')} №{index + 1}
			</h2>
			<form>
				<div>
					<label>{t('firstname')}</label>
					<input
						type='text'
						value={value.firstName}
						onChange={e =>
							setValue(e.target.value, index, 'firstName')
						}
						className={error[index].firstName && 'emailError'}
					/>
				</div>
				<div>
					<label>{t('lastname')}</label>
					<input
						type='text'
						value={value.lastName}
						onChange={e =>
							setValue(e.target.value, index, 'lastName')
						}
						className={error[index].lastName && 'emailError'}
					/>
				</div>
				<div>
					<label>{t('date_of_birth')}</label>
					<input
						className={
							style.w100_webkit +
							' ' +
							(error[index].date && 'emailError')
						}
						type='date'
						value={value.date}
						onChange={e => setValue(e.target.value, index, 'date')}
					/>
				</div>
			</form>
		</div>
	)
}

export default ContactFormDop
