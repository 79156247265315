export const translation_ru = {
	main: 'Главная',
	search_tours: 'Поиск тура',
	contacts: 'Контакты',
	blog: 'Блог',
	get_offer: 'Получить предложение',
	//main-form
	from_to: 'Вылет',
	direction: 'Направление',
	choose_from_to: 'Выберите город отправления',
	choose_direction: 'Выберите направление',
	departure: 'Вылет',
	date: 'Дата',
	fill_in_the_field: 'Заполните поле',
	nights: 'Ночей',
	nights_register: 'ночей',
	flight_info: 'О перелётах',
	flight_info_full: 'Информация о перелётах',
	flight: 'рейс',
	not_have_data: 'Нет информации',
	country_visit: 'В страну пребывания',
	country_from: 'Из страны пребывания',
	guests: 'Гости',
	search: 'Поиск',
	searchFilter: 'Применить',
	meal: 'Питание',
	//Main_Page
	get_travel_dream:
		'Выберите лучшее предложение среди крупных туристических агентств',
	fs_main_description:
		'Мы предлагаем лучшую цену и дополнительную скидку в размере 5% с промокодом',
	fs_main_promocode: 'ATPUTA2024',
	//card-tour
	best_tour: 'Лучшие предложения',
	offer_mb_interest: 'Предложения, которые могут быть интересны',
	best_tour_description: 'В соотношении цены и качества',
	popular_tour: 'Популярные предложения',
	mb_get_offer: 'Желаете получить индивидуальное предложение?',
	we_find_variant: 'Мы найдём Вам идеальный вариант',
	get: 'Получить',
	principle_operation: 'Принцип работы',
	mandarina_app_has_many_advantages:
		'Приложение Mandarina имеет множество преимуществ',
	compliance_with_deadlines: 'Соблюдение сроков',
	compliance_with_deadlines_desc:
		'Своевременно и без задержек выполняем все необходимые действия для Вашего путешествия. В короткий срок подготовим предложение Вашего путешествия.',
	quality_assurance: 'Обеспечение качества',
	quality_assurance_desc:
		'Мы сотрудничаем только с надежными туристическими операторами и обеспечиваем сервис высокого уровня.',
	favorable_prices: 'Выгодные цены',
	favorable_prices_desc:
		'Мы предлагаем широкий выбор путешествий с возможностью сравнения цен, чтобы Вы могли выбрать для себя наиболее подходящий и выгодный вариант.',
	feedback_from_our_customers: 'Отзывы наших клиентов',
	we_glad_feedback:
		'Мы рады, что большинство наших клиентов – это люди, с которыми у нас сложились постоянные и доверительные отношения.',
	feedback_from_our_customers_1:
		'Давно хотели полететь отдохнуть выбор сразу пал на Mandarin 👌Остались очень довольны, нам быстро подобрали варианты под наш запрос и огромный плюс что есть акция раннего бронирования + забронировать можно дистанционно. Ждём нашу поездку ❤️',
	feedback_from_our_customers_2:
		'хороший отдых! спасибо! остались очень довольны!',
	feedback_from_our_customers_3:
		'Очень классно отдохнули в Греции. Отвечают оперативно, цены приятные!',
	feedback_from_our_customers_4:
		'Рекомендую данную компанию - очень оперативно отвечают!',
	feedback_from_our_customers_5:
		'Всё хорошо организовано и помощь от этой компании до самого конца.',
	feedback_from_our_customers_person_1: 'Iveta Babenko',
	feedback_from_our_customers_person_2: 'Кристина Родевича',
	feedback_from_our_customers_person_3: 'Дарья Кузнецова',
	feedback_from_our_customers_person_4: 'Андрей Богомолов',
	feedback_from_our_customers_person_5: 'Andrew Andrew',
	tips_for_your_vacation_from_travel_experts:
		'Советы для твоего отдыха от экспертов по путешествиям!',
	get_inspired_for_your_next_trip:
		'Получите вдохновение для следующей поездки.',
	looks: 'Посмотреть',
	open_all_articles: 'Открыть все статьи',
	popular_destionations: 'Популярные направления',
	subtitleGR: 'Корфу, Крит, Родос',
	subtitleEG: 'Хургада, Шарм Эль Шейх',
	subtitleCN: 'Тенерифе',
	subtitleTR: 'Анталия, Аланья, Белек, Кемер, Сиде',
	subtitleME: 'Тиват',
	these_are_popular_destinations_over_the_past_month:
		'Куда отправиться в отпуск в 2024 году',
	over_14_years_old: 'Cтарше 14 лет',
	and_better: 'и лучше',
	number_of_years: 'Количество лет',
	from_2_to_14_years_old: 'До 14 лет',
	select_options: 'Выберите параметры',
	//meals
	without_power: 'Без питания',
	breakfast: 'Завтрак',
	half_board: 'Полупансион',
	full_board: 'Полный пансион',
	all_inclusive: 'Всё включено',
	all_inclusive_plus: 'Всё включено+',
	//country
	Bulgaria: 'Болгария',
	Italy: 'Италия',
	CanaryIslands: 'Канарские острова',
	Portugal: 'Португалия',
	Georgia: 'Грузия',
	Cyprus: 'Кипр',
	Tunisia: 'Тунис',
	Thailand: 'Таиланд',
	Mexico: 'Мексика',
	spain: 'Испания',
	egypt: 'Египет',
	turkey: 'Турция',
	greece: 'Греция',
	hotels: 'гостинницы',
	dominican_republic: 'Доминиканская республика',
	/////Mailgail_block
	get_news_to_your_email: 'Получайте новости на свою электронную почту',
	great_suggestions_ideas_and_tips_for_a_successful_trip:
		'Отличные предложения, идеи и советы для успешного путешествия',
	your_email: 'Ваша э-почта',
	subscribe: 'Подписаться',
	//footer
	additionally: 'Дополнительно',
	Menu: 'Меню',
	refund_policy: 'Политика возврата',
	security_policy: 'Политика безопасности',
	cookie_policy: 'Политика файлов cookie',
	terms_of_service: 'Условия предоставления услуг',
	all_rights_reserved: 'Все права защищены',
	social_mes: 'Соц. сети',
	a_service_for_quick_and_easy_registration_of_travel_anywhere_in_the_world:
		'Сервис для быстрого и простого оформления путешествий в любую точку мира',
	//search-tours
	travel_search: 'Поиск путешествия',
	title_first_screen_get_offer:
		'Заполни форму и получи индивидуальное предложение',
	travel_offers: 'предложений путешествий',
	which_hotel_did_you_choose: 'Какую гостиницу Вы выбрали?',
	Uploading_offers: 'Загрузка предложений...',
	price_range: 'Ценовой диапазон',
	city: 'Город',
	number_of_nights_reduction: 'Кол-во ночей',
	hotel_rating: 'Рейтинг гостиницы',
	hotel_location: 'Расположение',
	hotel_comfort: 'Удобства',
	for_kids: 'Для детей',
	for_active_rest: 'Активный отдых',
	type_of_food: 'Вид питания',
	reset: 'Сбросить',
	sort: 'Сортировать',
	//sort
	very_popular: 'Самые популярные',
	min_max: 'Минимальная цена - максимальная',
	max_min: 'Максимальная цена - минимальная',
	rating_min_max: 'Кол-во звёзд 1-5',
	rating_max_min: 'Кол-во звёзд 5-1',
	ta_rating: 'TripAdvisor рейтинг',
	google_rating: 'Google рейтинг',
	//
	filter: 'Фильтровать',
	delete: 'Удалить',
	the_duration_of_the_trip_from: 'Длительность поездки от:',
	the_price_includes: 'В цену включено:',
	the_price_includes_list:
		'перелет с багажом, гостиница, выбранный тип питания, помощь представителя и групповой трансфер',
	the_price_depends_on_the_date_of_departure_and_the_type_of_food:
		'*Цена зависит от даты вылета и типа питания',
	//hotel
	at_all: 'на всех',
	c: 'от',
	No_tours_found: 'Туров не найдено',
	Select_date: 'Выберите дату и проверьте доступность',
	price_up_to: 'Цена до',
	number_of_nights: 'Количество ночей',
	check_availability: 'Проверить доступность',
	departure_date: 'Дата вылета',
	number_of_seats_on_the_plane: 'Кол-во мест в самолёте',
	nights_reister: 'ночей',
	room_type: 'Тип номера',
	choose_date: 'Выберите дату',
	price_for_all: 'Цена на всех',
	search_for_suitable_offers: 'Поиск подходящих предложений',
	info_about_hotels: 'Информация о гостинице',
	services_for_children: 'Услуги для детей',
	activities: 'Активности',
	note: 'Примечание',
	not_a_info: 'Пока нет информации',
	location: 'Расположение',
	loading: 'Загрузка...',
	not_a_found: 'Ничего не найдено',
	not_info_about_hotel: 'По выбранным параметрам предложений нет',
	Use_the_search_to_get_offers:
		'Воспользуйтесь поиском чтобы получить предложения',
	order: 'Заказать',
	//get-offer
	when_do_you_want_to_go_on_a_trip: 'Выберите месяц',
	i_know_the_exact_dates: 'Я знаю точные даты',
	next_step: 'Следующий шаг',
	where_do_you_want_to_go: 'Выберите место назначения',
	select_the_number_of_people: 'Выберите количество человек',
	adults: 'Взрослые',
	adult_checout: 'взрослого',
	adults_checout: 'взрослых',
	childs_checout: 'ребенка',
	Promo_code_discount: 'Скидка по промокоду',
	placeholder_promocode: 'Введите промокод',
	childs: 'Дети',
	babyes: 'Малыши',
	Uzdiviem: 'На двоих',
	select_a_price_range: 'Выберите ценовой диапазон',
	we_will_try_to_find_the_best_offers_for_you:
		'Мы постараемся подыскать для Вас самые лучшие предложения',
	suggestions_and_comments: 'Пожелания и комментарии',
	if_you_have_any_additional_requests_or_comments_in_connection_with_the_trip_write_to_us:
		'Если у Вас имеются дополнительные пожелания или комментарии в связи с поездкой, напишите нам',
	hotel_room_type_catering_other: 'Отель, тип номера, питание, другие',
	leave_your_contact_information_so_that_we_can_contact_you:
		'Оставьте свою контактную информацию, чтобы мы могли с Вами связаться',
	firstname: 'Имя',
	lastname: 'Фамилия',
	phone: 'Номер телефона',
	email: 'Э-почта',
	enter_your_name_here: 'Введите свое имя здесь',
	enter_your_last_name_here: 'Введите свою фамилию здесь',
	last_name: 'Фамилия',
	enter_your_phone_number_here: 'Введите номер своего телефона здесь',
	enter_your_email_address_here: 'Введите здесь адрес своей э-почты',
	back: 'Назад',
	this_field_is_required: 'Это поле обязательно',
	//month
	may: 'Май',
	may_pad: 'Мая',
	june: 'Июнь',
	june_pad: 'Июня',
	july: 'Июль',
	july_pad: 'Июля',
	august: 'Август',
	august_pad: 'Августа',
	september: 'Сентябрь',
	september_pad: 'Сентября',
	october: 'Октябрь',
	october_pad: 'Октября',
	november: 'Ноябрь',
	november_pad: 'Ноября',
	december: 'Декабрь',
	december_pad: 'Декабря',
	january: 'Январь',
	january_pad: 'Января',
	february: 'Февраль',
	february_pad: 'Февраля',
	mart: 'Март',
	mart_pad: 'Марта',
	april: 'Апрель',
	april_pad: 'Апреля',
	//FromTo
	riga: 'Рига',
	talin: 'Таллин',
	vilnus: 'Вильнюс',
	montenegro: 'Черногория',
	//checkout:'',
	filling_in_the_data: 'Заполнение данных',
	please_fill_in_your_details: 'Пожалуйста, заполните ваши данные',
	booking_information: 'Информация о бронировании',
	change: 'Изменить',
	hotel: 'Отель',
	type_number: 'Тип номера',
	contact_information: 'Турист',
	phone_number: 'Телефонный номер',
	adres_email: 'Email',
	date_of_birth: 'Дата рождения',
	price_details: 'Детали цены',
	price_for: 'Цена за',
	final_price: 'Итоговая цена',
	lietot: 'Применить',
	prepayment: 'Оплата сейча',
	payment_after: 'Оплата после',
	method_pay: 'Метод оплаты',
	payment_by_card_or_internet_bank: 'Оплата картой',
	receive_an_invoice_by_email: 'Получить счёт на электронную почту',
	i_agree_with: 'Я согласен с',
	and: 'и',
	terms_of_service_v2: 'условиями предоставления услуг',
	refund_rules_v2: 'правилами возврата',
	go_to_pay: 'Перейти к оплате',
	///
	two_adults: 'Двое взрослых',
	not_have_offers: 'Нет ближайших предложений',
	hotels_v2: 'отелей',
	///contacts
	ocntact_of_company: 'Контакты компании Mandarina',
	contact_info_contact_pay: 'Контактная информация и реквизиты',
	call_us_contact: 'Свяжитесь с нами',
	our_request: 'Ваш запрос',
	our_req_text:
		'Здесь Вы можете задать вопрос или описать ситуацию, с которой Вы столкнулись.',
	send: 'Отправить',
	reqvisit: 'Реквизиты',
	jar_addres: 'Юр. адрес',
	reg_number: 'Рег. номер',
	license: 'лицензия',
	last_asking: 'Остались какие-то вопросы?',
	call_us_and: 'Свяжитесь с нами удобным для Вас способом.',
	sale_departament: 'Отдел продаж',
	ask_for_sale_departament: 'Для вопросов о сотрудничестве',
	help_departament: 'Отдел помощи',
	ask_for_help_departament: 'Для вопросов о путешествиях',
	////payment-successful
	thank_you_for_order: 'Спасибо за заказ!',
	thank_you_for_order_text:
		'Вся информация по резервации и документам будет отправлена на указанный адрес электронной почты.',
	back_to_home_button: 'Вернуться на главный экран',
	kontakti: 'Контакты',
	not_found: 'Страница не найдена',
	subscribe_text: 'Вы успешно подписались',
	address_text: 'Спасибо за ваше обращение, мы с вами свяжемся!',
	rate_cleanliness: 'Чистота',
	rate_room: 'Номер',
	rate_value: 'Цена',
	rate_service: 'Сервис',
	rate_sleep: 'Качество сна',
	rate_location: 'Расположение',
	read_all_review: 'Читать все отзывы',
	guests_mark: 'Посетители отметили:',
	hotelReviews_reviews: 'Отзывы о гостинице',
	The_average_rating_reviews_Google_Tripadvisor:
		'Средняя оценка отеля по отзывам посетителей от Google и Tripadvisor',
	atsauksmes: 'отзывов',
	Unwrap: 'Развернуть',
	All_reviews: 'Все отзывы',
	error_fromTownCode: 'Выберите город вылета',
	error_countryCode: 'Выберите страну',
	error_date: 'Выберите дату',
	error_meal_types: 'Выберите тип питания',
	formInfoError_date: 'Введите дату рождения',
	formInfoError_email: 'Введите почту',
	formInfoError_firstName: 'Введите имя',
	formInfoError_lastName: 'Введите фамилию',
	formInfoError_phone: 'Введите номер телефона',
	formInfoError_payment: 'Выберите метод оплаты',
	// meal types
	UAI: 'Все включено+',
	ALACARTEAI: 'Все включено (a la carte)',
	UltraAI: 'Все включено+',
	AI: 'Все включено',
	FB: 'Полный пансион',
	HB: 'Полупансион',
	RO: 'Только в номере',
	BB: 'Завтрак',
	SC: 'Без питания',

	paymentFailure_for_order: 'Платеж не прошел',
	paymentFailure_for_order_text: 'Попробуйте еще раз или свяжитесь с нами.',
	day: 'дня',
	beach: 'Пляж'
}
