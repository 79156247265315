import { GetOfferData2 } from '@/components/screens/get-offer/get-offer1/get-offer1.data'

export const ConvertDateToConvert = (date: string, t?: any) => {
	const newDate = date.split('-')
	return `${parseInt(newDate[2])} ${t(
		GetOfferData2[parseInt(newDate[1]) - 1].monthDate
	)}`
}

export const ConvertDateToConvertArray = (date: string, t?: any) => {
	let newDateArray = date.split(',')
	let stringReturn = ''
	newDateArray.forEach((element, key) => {
		if (element == '') {
			return ''
		}
		const newDate = element.split('-')
		stringReturn += `${parseInt(newDate[2])} ${t(
			GetOfferData2[parseInt(newDate[1]) - 1].monthDate
		)}`
		if (key < newDateArray.length - 1) {
			stringReturn += ', '
		}
	})

	return stringReturn
}

export const ConvertDateToConvertYear = (date?: string, t?: any) => {
	const newDate = date?.split('-')
	if (!newDate) return ''
	return `${parseInt(newDate[2])} ${t(
		GetOfferData2[parseInt(newDate[1]) - 1].monthDate
	)} ${newDate[0]}`
}
