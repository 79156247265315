import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import 'react-image-gallery/styles/scss/image-gallery.scss'

import { Routes, Route, useLocation, Switch } from 'react-router-dom'
import Home from './components/screens/Home/Home'
import SearchPage from './components/screens/search-page/SearchPage'
import Hotel from '@/components/screens/hotel/Hotel'
import Search from '@/components/screens/search/Search'
import GetOffer from '@/components/screens/get-offer/GetOffer'
import Blog from '@/components/screens/blog/Blog'
import BlogId from '@/components/screens/blog/blog-id/BlogId'
import CookiePage from '@/components/screens/cookie/CookiePage'
import ReturnPolicy from '@/components/screens/return-policy/ReturnPolicy'
import PrivacyPolicy from '@/components/screens/privacy-policy/PrivacyPolicy'
import TermsPage from '@/components/screens/terms/TermsPage'
import Contacts from '@/components/screens/contacts/Contacts'
import Checkout from '@/components/screens/checkout/Checkout'
import 'react-toastify/dist/ReactToastify.css'
import { SearchToursService } from './services/search-tours/SearchToursService.service'
import { useMutation, useQuery } from 'react-query'
import 'react-loading-skeleton/dist/skeleton.css'
import { BlogService } from './services/blog/blog.service'
import { LangContext } from './components/provider/MainProvider'
import PaymentSuccessful from "@/pages/paymentSuccessful/paymentSuccessful";
import PaymentFailure from "@/pages/paymentFailure/paymentFailure";

import NotFound from "@/pages/notFound/notFound";
import { Helmet } from 'react-helmet'

function App() {
	const [tours, setTours] = useState()
	const [loading, setLoading] = useState(true)
	const [timeData, setTimeData] = useState(
		localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo') || '')
			: {
				adult: 2,
				child: 0,
				childs_age: [],
				countryCode: '',
				data: '',
				meal_types: ['RO', 'BB', 'HB', 'FB', 'AI', 'UAI'],
				nights_max: 7,
				nights_min: 5,
				price_range_max: 10000,
				price_range_min: 100,
				townFrom: '',
				rating: [true, true, true, true, true],
				date_check_range: false,
				beach: false,
				for_kids: false,
				for_active_rest: false,
			}
	)

	const [checkout, setCheckout] = useState(
		localStorage.getItem('checkout')
			? JSON.parse(localStorage.getItem('checkout') || '')
			: {}
	)
	const { lang, toggleLang: setLang } = useContext(LangContext)


	const getPost = useQuery(['get-posts', lang], () => BlogService.getBlog(lang), {
		select: data =>
			data.data.map(el => ({
				country: el.country,
				created_at: el.created_at,
				image: el.image,
				id: el.id,
				title: el?.title || el.title_lv,
				url: el.url,
				description: el?.description || el.description_lv
			}))
	})
	const searchToursMain = useMutation(
		'search-tours-main',
		data => SearchToursService.getSearchTours(data),
		{
			onSuccess: data => setTours(data.data)
		}
	)
	const { pathname } = useLocation()

	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant'
		})
	}, [pathname])
	useEffect(() => {
		//setLoading('1')
	}, [])
	return (
		<>
			<>
				<Helmet>
					<title>
						Ceļojumu aģentūra Mandarina - atrodi savu sapņu ceļojumu
					</title>

				</Helmet>
				<Routes>
					<Route
						path='/'
						element={
							<Home
								setTours={setTours}
								timeData={timeData}
								setTimeData={setTimeData}
								loadingFoot={loading}
								setLoadingFoot={setLoading}
								getPost={getPost}
							/>
						}
					/>
					<Route
						path='/search'
						element={
							<Search
								timeData={timeData}
								setTimeData={setTimeData}
								loading={loading}
								setLoading={setLoading}
							/>
						}
					/>
					<Route
						path='/contacts'
						element={<Contacts />}
					/>
					<Route
						path='/blog'
						element={
							<Blog
								getPost={getPost}
							/>
						}
					/>
					<Route
						path='/blog/:id'
						element={<BlogId />}
					/>
					<Route
						path='/get-offer'
						element={<GetOffer />}
					/>
					<Route
						path='/get-offer-ru'
						element={<GetOffer lang='ru' />}
					/>
					<Route
						path='/hotel/:id'
						element={
							<Hotel
								timeData={timeData}
								setTimeData={setTimeData}
								checkout={checkout}
								setCheckout={setCheckout}
							/>
						}
					/>
					<Route
						path='/cookies'
						element={<CookiePage />}
					/>
					<Route
						path='/return-policy'
						element={<ReturnPolicy />}
					/>
					<Route
						path='/privacy-policy'
						element={
							<PrivacyPolicy />
						}
					/>
					<Route
						path='/terms'
						element={<TermsPage />}
					/>
					<Route
						path='/checkout'
						element={
							<Checkout
								checkout={checkout}
								setCheckout={setCheckout}
							/>
						}
					/>
					<Route
						path='/payment-successful'
						element={
							<PaymentSuccessful

							/>
						}
					/>

					<Route
						path='/payment-failure'
						element={
							<PaymentFailure />
						}
					/>

					<Route
						path='/search-tours'
						element={
							<SearchPage
								tours={tours}
								setTours={setTours}
								timeData={timeData}
								setTimeData={setTimeData}
								searchToursMain={searchToursMain}
								loading={loading}
								setLoading={setLoading}
							/>
						}
					/>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</>
			{/* {!loading && <Footer />} */}

			{/* </Wrapper> */}
		</>
	)
}

export default App
