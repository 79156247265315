export const ApiData = {
	countryCode: ['eg','gr', 'tr', 'cn','me' ],
	directionsData: [
		{
			name: 'riga',
			code: 'lv'
		},
		{
			name: 'talin',
			code: 'ee'
		},
		{
			name: 'vilnus',
			code: 'lt'
		}
	],
	directionsData2: [
		{
			name: 'greece',
			code: 'gr'
		},
		{
			name: 'egypt',
			code: 'eg'
		},
		{
			name: 'spain',
			code: 'cn'
		},
		{
			name: 'montenegro',
			code: 'me'
		},
		{
			name: 'turkey',
			code: 'tr'
		}
	],
	nutritionType: [
		{ name: 'without_power', code: 'RO' },
		{ name: 'breakfast', code: 'BB' },
		{ name: 'half_board', code: 'HB' },
		{ name: 'full_board', code: 'FB' },
		{ name: 'all_inclusive', code: 'AI' },
		{ name: 'all_inclusive_plus', code: 'UAI' }
	],
	directionsData2GetOffer: [
		{
			name: 'greece',
			code: 'greece'
		},
		{
			name: 'egypt',
			code: 'egypt'
		},
		{
			name: 'spain',
			code: 'spain'
		},
		{
			name: 'montenegro',
			code: 'montenegro'
		},
		{
			name: 'turkey',
			code: 'turkey'
		},
		
		{
			name: 'Bulgaria',
			code: 'Bulgaria'
		},
		{
			name: 'Italy',
			code: 'Italy'
		},
		{
			name: 'CanaryIslands',
			code: 'CanaryIslands'
		},
		{
			name: 'Portugal',
			code: 'Portugal'
		},
		{
			name: 'Georgia',
			code: 'Georgia'
		},
		{
			name: 'Cyprus',
			code: 'Cyprus'
		},
		{
			name: 'Tunisia',
			code: 'Tunisia'
		},
		{
			name: 'Thailand',
			code: 'Thailand'
		},
		{
			name: 'Mexico',
			code: 'Mexico'
		}
	],
}
