import React, { FC } from 'react'
import s from './FllightInfo.module.scss'
import airplane from './../../../../assets/images/airplane.png'
import { useTranslation } from 'react-i18next'

const replaceDateString = (date: string) => {
	let year = date.slice(0, 4)
	let month = date.slice(5, 7)
	let day = date.slice(8, 10)
	return day + '.' + month + '.' + year
}

const FlightInfo: FC<any> = ({ info, onClose }) => {
	const data = info?.data?.transferInfo

	const { t } = useTranslation()

	return (
		<>
			{info?.open && info.data.transferInfo ? (
				<div className={s.flightInfo_wrapper}>
					<div className={s.popup}>
						<div className={s.title}>
							<p className={s.titleText + ' ' + s.title_mr}>
								{t('flight_info_full')}
							</p>
							<div
								onClick={() =>
									onClose({ data: null, open: false })
								}
								className={s.cross}
							></div>
						</div>
						<div className={s.container}>
							<div className={s.box}>
								<p className={s.titleText + ' ' + s.mb_20}>
									{t('country_visit')}
								</p>
								<div className={s.row + ' ' + s.mb_8}>
									<p
										className={
											s.textGrey + ' ' + s.titleText
										}
									>
										{t('date')}:&nbsp;
									</p>
									<p
										className={
											s.textBlack + ' ' + s.titleText
										}
									>
										{data?.[0]?.date
											? replaceDateString(data[0].date)
											: t('not_have_data')}
									</p>
								</div>
								<div className={s.row + ' ' + s.mb_20}>
									<p
										className={
											s.textGrey + ' ' + s.titleText
										}
									>
										{t('flight')}:&nbsp;
									</p>
									<p
										className={
											s.textBlack + ' ' + s.titleText
										}
									>
										{data?.[0]?.flightName
											? data?.[0]?.flightName
											: t('not_have_data')}
									</p>
								</div>
								<div className={s.row + ' ' + s.column}>
									<div className={s.timeAndFlight}>
										<p
											className={
												s.textBlack +
												' ' +
												s.titleText +
												' ' +
												s.mb_8
											}
										>
											{data[0]?.sourceTown
												? data[0]?.sourceTown
												: t('not_have_data')}
										</p>
										<div className={s.row}>
											<p className={s.timeCode}>
												{data[0]?.departure?.portAlias
													? data[0]?.departure
															?.portAlias
													: t('not_have_data')}
												&nbsp;
											</p>
											<p
												className={
													s.time + ' ' + s.timeCode
												}
											>
												{data[0]?.departure.time}
											</p>
										</div>
									</div>

									<div className={s.blueLineContainer}>
										<div
											className={
												s.blueLine + ' ' + s.blueLine1
											}
										></div>
										<img
											src={airplane}
											alt=''
											className={s.airplane}
										/>
										<div
											className={
												s.blueLine + ' ' + s.blueLine2
											}
										></div>
									</div>

									<div className={s.timeAndFlight}>
										<p
											className={
												s.textBlack +
												' ' +
												s.titleText +
												' ' +
												s.mb_8
											}
										>
											{data[0]?.targetTown
												? data[0]?.targetTown
												: t('not_have_data')}
										</p>
										<div className={s.row}>
											<p className={s.timeCode}>
												{data[0]?.arrival?.portAlias
													? data[0]?.arrival
															?.portAlias
													: t('not_have_data')}
												&nbsp;
											</p>
											<p
												className={
													s.time + ' ' + s.timeCode
												}
											>
												{data[0]?.arrival.time}
											</p>
										</div>
									</div>
								</div>
							</div>

							{/*2-й box*/}

							<div className={s.box + ' ' + s.column}>
								<p className={s.titleText + ' ' + s.mb_20}>
									{t('country_from')}
								</p>
								<div className={s.row + ' ' + s.mb_8}>
									<p
										className={
											s.textGrey + ' ' + s.titleText
										}
									>
										{t('date')}:&nbsp;
									</p>
									<p
										className={
											s.textBlack + ' ' + s.titleText
										}
									>
										{data[1]?.date
											? replaceDateString(data[1]?.date)
											: t('not_have_data')}
									</p>
								</div>
								<div className={s.row + ' ' + s.mb_20}>
									<p
										className={
											s.textGrey + ' ' + s.titleText
										}
									>
										{t('flight')}:&nbsp;
									</p>
									<p
										className={
											s.textBlack + ' ' + s.titleText
										}
									>
										{data[1]?.flightName
											? data[1]?.flightName
											: t('not_have_data')}
									</p>
								</div>
								<div className={s.row + ' ' + s.column}>
									<div className={s.timeAndFlight}>
										<p
											className={
												s.textBlack +
												' ' +
												s.titleText +
												' ' +
												s.mb_8
											}
										>
											{data[1]?.sourceTown
												? data[1]?.sourceTown
												: t('not_have_data')}
										</p>
										<div className={s.row}>
											<p className={s.timeCode}>
												{data[1]?.departure.portAlias
													? data[1]?.departure
															.portAlias
													: t('not_have_data')}
												&nbsp;
											</p>
											<p
												className={
													s.time + ' ' + s.timeCode
												}
											>
												{data[1]?.departure.time}
											</p>
										</div>
									</div>

									<div className={s.blueLineContainer}>
										<div
											className={
												s.blueLine + ' ' + s.blueLine1
											}
										></div>
										<img
											src={airplane}
											alt=''
											className={s.airplane}
										/>
										<div
											className={
												s.blueLine + ' ' + s.blueLine2
											}
										></div>
									</div>

									<div className={s.timeAndFlight}>
										<p
											className={
												s.textBlack +
												' ' +
												s.titleText +
												' ' +
												s.mb_8
											}
										>
											{data[1]?.targetTown
												? data[1]?.targetTown
												: t('not_have_data')}
										</p>
										<div className={s.row}>
											<p className={s.timeCode}>
												{data[1]?.arrival?.portAlias
													? data[1]?.arrival
															?.portAlias
													: t('not_have_data')}
												&nbsp;
											</p>
											<p
												className={
													s.time + ' ' + s.timeCode
												}
											>
												{data[1]?.arrival?.time}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				info?.open && (
					<div className={s.flightInfo_wrapper}>
						<div className={s.popup}>
							<div className={s.title}>
								<p className={s.titleText + ' ' + s.title_mr}>
									{t('not_have_data')}
								</p>
								<div
									onClick={() => onClose({ open: false })}
									className={s.cross}
								></div>
							</div>
						</div>
					</div>
				)
			)}
		</>
	)
}

export default FlightInfo
