import React, { FC, useEffect, useState } from 'react'
import Header from '../Home/header/Header'
import bgImg from '@/assets/images/BG_getAnOffer_bg.webp'
import style from './GetOffer.module.scss'
import GetOffer1 from './get-offer1/GetOffer1'
import { TypeStateGetOffer } from './get-offer.interface'
import StepGetOffer from './ui/step-get-offer/StepGetOffer'
import GetOffer2 from './get-offer2/GetOffer2'
import GetOfferButton from './ui/get-offer-button/GetOfferButton'
import GetOfferBack from './ui/get-offer-back/GetOfferBack'
import GetOffer3 from './get-offer3/GetOffer3'
import GetOffer4 from './get-offer4/GetOffer4'
import GetOffer5 from './get-offer5/GetOffer5'
import GetOffer6 from './get-offer6/GetOffer6'
import Footer from '../footer/Footer'
import { useTranslation } from 'react-i18next'

const GetOffer: FC<{ lang: string }> = props => {
	const [state, setState] = useState<TypeStateGetOffer>({
		step: 1,
		countryCode: '',
		month: '',
		childs: 0,
		chidls_age: [],
		adults: 2,
		comment: '',
		form: {
			firstName: '',
			lastName: '',
			phone: '',
			email: ''
		},
		peopleBaby: 0,
		price_range_min: null,
		price_range_max: null
	})
	const { t, i18n } = useTranslation()
	useEffect(() => {
		props.lang == 'ru' ? i18n.changeLanguage('ru') : console.log('test')
	}, [])

	return (
		<>
			<div className='bg-gray-wrapper'>
				<Header />
			</div>
			<div className={style.margin}></div>
			<div
				className={style.content + ' ' + 'container-xxl'}
				style={{ backgroundImage: `url(${bgImg})` }}
			>
				<div>
					<div className={style.cont}>
						<h1 className={style.title_first}>
							{t('title_first_screen_get_offer')}
						</h1>
						<StepGetOffer state={state} />
						{state.step === 1 && (
							<GetOffer1 state={state} setState={setState} />
						)}
						{state.step === 2 && (
							<GetOffer2 state={state} setState={setState} />
						)}
						{state.step === 3 && (
							<GetOffer3 state={state} setState={setState} />
						)}
						{state.step === 4 && (
							<GetOffer4 state={state} setState={setState} />
						)}
						{state.step === 5 && (
							<GetOffer5 state={state} setState={setState} />
						)}
						{state.step === 6 && (
							<GetOffer6 state={state} setState={setState} />
						)}

						{state.step !== 1 && (
							<GetOfferBack setState={setState}>
								{t('back')}
							</GetOfferBack>
						)}
					</div>
				</div>
				{/*<img src={bgImg} alt='bg-image' />*/}
			</div>
			<Footer />
		</>
	)
}

export default GetOffer
