import React, { FC, useEffect, useState } from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { useTranslation } from 'react-i18next'
import s from './Hotel.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination])

export interface HotelReviews {
	id: number
	author: string
	author_country_code: string
	created_at: string
	hotelCode: string
	published_date: number
	rating: number
	rating_image_url: string
	text: string
	title: string
	travel_date: string
}

interface GoogleReviews {
	author_name: string
	author_url: string
	language: string
	original_language: string
	profile_photo_url: string
	rating: number
	relative_time_description: string
	text: string
	time: number
	translated: boolean
}

const ModalReviewTxt: FC<any> = ({
	el,
	currentLanguage,
	openModal,
	setOpenModal
}) => {
	const [dataCreate, setDataCreate] = useState(el.published_date || el.time)
	useEffect(() => {
		setDataCreate(el.published_date || el.time)
	}, [el])
	return (
		<div
			className={
				s.wrapper_modal + ' ' + (openModal && s.wrapper_modal_active)
			}
		>
			<div className={s.bodyModal}>
				<button
					className={s.bodyModal_close}
					onClick={() => setOpenModal(false)}
				></button>
				<div className={s.reviews_row_title}>
					<div className={s.reviews_wrapper_icon_name}>
						<div
							className={
								el.time ? s.google_user_icon : s.trip_user_icon
							}
						>
							{el.time && (
								<img src={el.profile_photo_url} alt='' />
							)}
						</div>
						<div className={s.reviews_author_published_date}>
							<div className={s.reviews_author}>
								{el.author || el.author_name}
							</div>
							<p className={s.reviews_published_date}>
								{new Date(dataCreate * 1000).toLocaleString(
									currentLanguage,
									{
										year: 'numeric',
										month: 'long',
										day: 'numeric'
									}
								)}
							</p>
						</div>
					</div>

					<div className={s.reviews_rating_wrapper}>
						<div>{el.rating}</div>
						<div className={s.img_star}></div>
					</div>
				</div>
				<div>
					<p className={s.reviews_txt}>{el.text}</p>
				</div>
			</div>
		</div>
	)
}

const ReviewTxt: FC<any> = ({
	txt,
	activeIndexSlide,
	setCurrentReviewModal,
	el,
	setOpenModal
}) => {
	const { t, i18n } = useTranslation()
	const maxLength = 200
	const [wrapTxt, serWrapTxt] = useState(
		txt.length > maxLength ? true : false
	)
	const [activeIndex, setActiveIndex] = useState(0)
	useEffect(() => {
		setActiveIndex(activeIndexSlide)
		activeIndex != activeIndexSlide && serWrapTxt(true)
	}, [activeIndexSlide])

	function sliceString(str: string, maxLength: number) {
		if (str.length > maxLength) {
			return str.slice(0, maxLength) + '...'
		}
		return str
	}

	return (
		<>
			{wrapTxt ? sliceString(txt, maxLength) : txt}
			<button
				onClick={() => {
					setCurrentReviewModal(el)
					setOpenModal(true)
				}}
				className={
					s.btn_wrapTxt +
					(txt.length < maxLength ? ' ' + s.btn_wrapTxt_hide : '')
				}
			>
				{wrapTxt ? t('Unwrap') : 'Свернуть'}
			</button>
		</>
	)
}

const Reviews: FC<any> = ({
	hotelReviews,
	currentLanguage,
	hotelReviewsGoogle,
	setSwiperRef,
	currentReview
}) => {
	currentLanguage == 'lv' &&
		hotelReviews.sort((a: HotelReviews, b: HotelReviews) => b.id - a.id)

	const [activeIndexSlide, setActiveIndexSlide] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [currentReviewModal, setCurrentReviewModal] = useState<
		HotelReviews | GoogleReviews
	>(hotelReviews?.[0] || hotelReviewsGoogle)
	const handleSlideChange = (e: any) => {
		setActiveIndexSlide(e.activeIndex)
	}
	const isMobile = window.innerWidth < 991
	return (
		<>
			<ModalReviewTxt
				el={currentReviewModal}
				currentLanguage={currentLanguage}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
			<div className={s.reviews_wrapper}>
				<Swiper
					spaceBetween={30}
					slidesPerView={2}
					pagination={{
						clickable: true,
						dynamicBullets: true,
						dynamicMainBullets: 3
					}}
					onSwiper={swiper => setSwiperRef(swiper)}
					className={s.swiperContainer + ' swiperContainer_ReviewTxt'}
					navigation={{
						prevEl: '.swiper-button-prev',
						nextEl: '.swiper-button-next'
					}}
					breakpoints={{
						300: {
							slidesPerView: 1, // Конфигурация для экранов шириной 576px и больше
							spaceBetween: 20
						},
						991: {
							slidesPerView: 2, // Конфигурация для экранов шириной 576px и больше
							spaceBetween: 30
						}
					}}
					onSlideChange={handleSlideChange}
				>
					{currentReview == 'tripadvisor' || currentReview == 'all'
						? hotelReviews.map((el: HotelReviews) => (
								<SwiperSlide key={el.id}>
									<div className={s.reviews_item}>
										<div className={s.reviews_row_title}>
											<div
												className={
													s.reviews_wrapper_icon_name
												}
											>
												<div
													className={s.trip_user_icon}
												></div>
												<div
													className={
														s.reviews_author_published_date
													}
												>
													<div
														className={
															s.reviews_author
														}
													>
														{el.author}
													</div>
													<p
														className={
															s.reviews_published_date
														}
													>
														{new Date(
															el.published_date *
																1000
														).toLocaleString(
															currentLanguage,
															{
																year: 'numeric',
																month: 'long',
																day: 'numeric'
															}
														)}
													</p>
												</div>
											</div>

											<div
												className={
													s.reviews_rating_wrapper
												}
											>
												<div>{el.rating}</div>
												<div
													className={s.img_star}
												></div>
											</div>
										</div>
										<div>
											<p className={s.reviews_txt}>
												<ReviewTxt
													txt={el.text}
													activeIndexSlide={
														activeIndexSlide
													}
													el={el}
													setCurrentReviewModal={
														setCurrentReviewModal
													}
													setOpenModal={setOpenModal}
												/>
											</p>
										</div>
									</div>
								</SwiperSlide>
						  ))
						: null}

					{currentReview == 'google' || currentReview == 'all'
						? hotelReviewsGoogle.map((el: GoogleReviews) => (
								<SwiperSlide>
									<div className={s.reviews_item}>
										<div className={s.reviews_row_title}>
											<div
												className={
													s.reviews_wrapper_icon_name
												}
											>
												<div
													className={
														s.google_user_icon
													}
												>
													<img
														src={
															el.profile_photo_url
														}
														alt=''
													/>
												</div>
												<div
													className={
														s.reviews_author_published_date
													}
												>
													<div
														className={
															s.reviews_author
														}
													>
														{el.author_name}
													</div>
													<p
														className={
															s.reviews_published_date
														}
													>
														{new Date(
															el.time * 1000
														).toLocaleString(
															currentLanguage,
															{
																year: 'numeric',
																month: 'long',
																day: 'numeric'
															}
														)}
													</p>
												</div>
											</div>

											<div
												className={
													s.reviews_rating_wrapper
												}
											>
												<div>{el.rating}</div>
												<div
													className={s.img_star}
												></div>
											</div>
										</div>
										<div>
											<p className={s.reviews_txt}>
												<ReviewTxt
													txt={el.text}
													activeIndexSlide={
														activeIndexSlide
													}
													el={el}
													setCurrentReviewModal={
														setCurrentReviewModal
													}
													setOpenModal={setOpenModal}
												/>
											</p>
										</div>
									</div>
								</SwiperSlide>
						  ))
						: null}

					{isMobile && (
						<>
							<div
								className={
									s.reviews_wrapper_swiper_button_prev +
									' swiper-button-prev'
								}
							></div>
							<div
								className={
									s.reviews_wrapper_swiper_button_next +
									' swiper-button-next'
								}
							></div>
						</>
					)}
				</Swiper>
			</div>
		</>
	)
}

export default Reviews
