import React, { FC } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const MapGoogle: FC<{ latitude: string; longitude: string }> = props => {
	const mapStyles = {
		height: '400px',
		width: '100%',
		borderRadius: '20px'
	}

	const defaultCenter = {
		lat: Number(props.latitude),
		lng: Number(props.longitude)
	}
	return (
		<>
			<LoadScript googleMapsApiKey='AIzaSyACme3H0rb-2uyTmMD7SqZzRoyS8WEopAE'>
				<GoogleMap
					mapContainerStyle={mapStyles}
					zoom={16} // Уровень масштабирования карты
					center={defaultCenter} // Центр карты
				>
					<Marker position={defaultCenter} />
				</GoogleMap>
			</LoadScript>
		</>
	)
}

export default MapGoogle
