import { SearchToursService } from '@/services/search-tours/SearchToursService.service'
import React from 'react'
import { useQuery } from 'react-query'
import defaultImg from '@/assets/images/default-home.svg'

const usePopularHotel = () => {
  const getPopularHotels = useQuery(
		'get-popular-hotels',
		() => SearchToursService.getPopular(),
		{
			select: data => {
				const dataSet = data.data.map((el: any) => ({
					images: el.photoList.length !== 0 ? el.photoList.map(
						(el: any) => `https://api.mandarina.lv/${el.urlPhoto}`
					) : [defaultImg],
					price: el.price,
					ta_rating_image_url: el.ta_rating_image_url,
					name: el.name,
					latitude: el.latitude,
					longitude: el.longitude,
					nights: el.nights,
					hotelCode: el.hotelCode,
					rating: el.rating,
					location_lv:el.location_lv,
					location_ru:el.location_ru,
					location_en:el.location_en,
					ta_rating:el.ta_rating,
				}))
				return dataSet
			}
		}
	)
  return getPopularHotels
}

export default usePopularHotel