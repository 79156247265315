import React, { FC, useContext, useState } from 'react'
import './Contacts.scss'

import partners1 from '@/assets/images/contacts/partners/image partners.png'
import partners2 from '@/assets/images/contacts/partners/image partners2.png'
import partners3 from '@/assets/images/contacts/partners/image partners3.png'
import partners4 from '@/assets/images/contacts/partners/image partners4.png'
import partners5 from '@/assets/images/contacts/partners/image partners5.png'
import partners6 from '@/assets/images/contacts/partners/image partners6.png'
import doc from '@/assets/docs/licence.pdf'
import headphones from '@/assets/images/contacts/headphones.svg'
import questionMark from '@/assets/images/contacts/question-mark.svg'
import Header from '../Home/header/Header'
import IndividualOffer from '../Home/individual-offer/IndividualOffer'
import MailingComp from '../Home/mailing-comp/MailingComp'
import { PropsSendUs } from '@/services/post-query/post-query.interface'
import { useMutation } from 'react-query'
import { PostQueryService } from '@/services/post-query/PostQuery'
import { toast } from 'react-toastify'
import Footer from '../footer/Footer'
import { LangContext } from '@/components/provider/MainProvider'
import { useTranslation } from 'react-i18next'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex =
	/^\+?[0-9]{1,3}[-. ]?\(?[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/

const Contacts: FC = () => {
	const { t } = useTranslation()
	const [phoneError, setPhoneError] = React.useState(false)
	const [mailError, setMailError] = React.useState(false)
	const [isPhoneBlur, setPhoneBlur] = React.useState(false)
	const [isMailBlur, setMailBlur] = React.useState(false)
	const { lang, toggleLang: setLang } = useContext(LangContext)
	const [value, setValue] = useState<PropsSendUs>({
		email: '',
		first_name: '',
		last_name: '',
		phone: '',
		txt_question: ''
	})
	const sendContacts = useMutation(
		'send-contacts',
		() => PostQueryService.contactForm(value),
		{
			onSuccess: () => {
				toast.success('Ваш запрос успешно зарегистрирован')
			}
		}
	)
	const onSubmit = (e: any) => {
		e.preventDefault()
		sendContacts.mutate()
	}

	const handleBlurPhone = () => {
		setPhoneBlur(false)
	}
	const handleFocusPhone = () => {
		setPhoneBlur(true)
	}
	const handleBlurMail = () => {
		setMailBlur(false)
	}
	const handleFocusMail = () => {
		setMailBlur(true)
	}
	const onChangeMail = (mail: string) => {
		setValue(state => ({
			...state,
			email: mail
		}))
		if (emailRegex.test(value.email) === true) {
			setMailError(false)
		} else {
			setMailError(true)
		}
	}
	const onChangePhone = (phone: string) => {
		setValue(state => ({
			...state,
			phone: phone
		}))
		if (phoneRegex.test(value.phone) === true) {
			setPhoneError(false)
		} else {
			setPhoneError(true)
		}
	}
	return (
		<>
			<div className='bg-gray-wrapper'>
				<Header lang={lang} setLang={setLang} />
			</div>
			<main>
				<div className='contacts'>
					<div className='container-xxl'>
						<div className='row'>
							<div className='col-12'>
								<h1 className='block_title center_title contact_title'>
									{t('ocntact_of_company')}
								</h1>
								<div className='block_description blog_desc'>
									{t('contact_info_contact_pay')}
								</div>
							</div>
						</div>
						<div className='row contacts_row'>
							<div className='col-12 col-lg-7'>
								<div className='form_contact_wrapper'>
									<form onSubmit={onSubmit}>
										<div className='form_name'>
											{t('call_us_contact')}
										</div>
										<div className='input_wpap_contact first'>
											<label htmlFor=''>
												{t('firstname')}
											</label>
											<input
												type='text'
												required
												value={value.first_name}
												onChange={e =>
													setValue(state => ({
														...state,
														first_name:
															e.target.value
													}))
												}
											/>
										</div>
										<div className='input_wpap_contact'>
											<label htmlFor=''>
												{t('lastname')}
											</label>
											<input
												type='text'
												required
												value={value.last_name}
												onChange={e =>
													setValue(state => ({
														...state,
														last_name:
															e.target.value
													}))
												}
											/>
										</div>
										<div className='input_wpap_contact'>
											<label htmlFor=''>
												{t('email')}
											</label>
											<input
												type='email'
												required
												value={value.email}
												onChange={e =>
													setValue(state => ({
														...state,
														email: e.target.value
													}))
												}
												onFocus={handleFocusMail}
												onBlur={handleBlurMail}
											/>
										</div>
										<div className='input_wpap_contact'>
											<label htmlFor=''>
												{t('phone')}
											</label>
											<input
												type='tel'
												required
												value={value.phone}
												onChange={e =>
													setValue(state => ({
														...state,
														phone: e.target.value
													}))
												}
												onFocus={handleFocusPhone}
												onBlur={handleBlurPhone}
											/>
										</div>
										<div className='input_wpap_contact'>
											<label htmlFor=''>
												{t('our_request')}
											</label>
											<textarea
												typeof='text'
												placeholder={
													t('our_req_text') || ''
												}
												value={value.txt_question}
												onChange={e =>
													setValue(state => ({
														...state,
														txt_question:
															e.target.value
													}))
												}
											/>
										</div>
										<button className='send_contact hvr-event'>
											{t('send')}
										</button>
									</form>
								</div>
							</div>
							<div className='col-12 col-lg-5'>
								<div className='contact_information'>
									<h2 className='requisites_title_txt'>
										{t('reqvisit')}
									</h2>
									iSYTC, SIA
									<br />
									<br />
									<span className='fw500'>
										PTAC {t('licence')}:
									</span>
									<a href={doc} target='_blank'>
										{' '}
										T-2019-265
									</a>
									<br />
									<span className='fw500'>
										{t('jar_addres')}:{' '}
									</span>
									Katrīnas dambis 24B - 12, Rīga, LV-1045
									<br />
									<span className='fw500'>
										{t('reg_number')}:{' '}
									</span>
									40103282473
									<br />
									<span className='fw500'>
										AS SEB banka:{' '}
									</span>
									LV23UNLA0050015289965
									<br />
									<br />
									<span className='fw500'>SWIFT: </span>
									UNLALV2X
									<br />
								</div>
							</div>
						</div>
					</div>
					<IndividualOffer />
					{/* <div className='container-xxl container_partners'>
						<div className='row'>
							<div className='col-12 row__partners'>
								<img src={partners1} alt='' />
								<img src={partners2} alt='' />
								<img src={partners3} alt='' />
								<img src={partners4} alt='' />
								<img src={partners5} alt='' />
								<img src={partners6} alt='' />
							</div>
						</div>
					</div> */}
					<div className='container-xxl questions_block'>
						<div className='row'>
							<div className='col-12'>
								<div className='still_questions_title'>
									{t('last_asking')}
								</div>
								<div className='block_description still_questions_description'>
									{t('call_us_and')}
								</div>
							</div>
						</div>
						<div className='row card_contacts_row'>
							<div className='col-12 col-lg-5'>
								<div className='body_card_contacts for_sale'>
									<img src={headphones} alt='' />
									<div className='body_card_contacts_title'>
										{t('sale_departament')}
									</div>
									<div className='body_card_contacts_description'>
										{t('ask_for_sale_departament')}
									</div>
									<a
										href='mailto:sales@mandarina.lv'
										className='body_card_contacts_a'
									>
										sales@mandarina.lv
									</a>
									<a
										href='tel:+371 26 619 971'
										className='body_card_contacts_a'
									>
										+371 26 619 971
									</a>
								</div>
							</div>
							<div className='col-12 col-lg-5'>
								<div className='body_card_contacts for_help'>
									<img src={questionMark} alt='' />
									<div className='body_card_contacts_title'>
										{t('help_departament')}
									</div>
									<div className='body_card_contacts_description'>
										{t('ask_for_help_departament')}:
									</div>
									<a
										href='mailto:info@mandarina.lv'
										className='body_card_contacts_a'
									>
										info@mandarina.lv
									</a>
									<a
										href='tel:+371 26 619 971'
										className='body_card_contacts_a'
									>
										+371 26 619 971
									</a>
								</div>
							</div>
						</div>
					</div>
					<MailingComp />
				</div>
			</main>
			<Footer />
		</>
	)
}

export default Contacts
