import 'flatpickr/dist/themes/material_blue.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { Latvian } from 'flatpickr/dist/l10n/lv.js'

//@ts-ignore
import Flatpickr from 'react-flatpickr'
import dateSvg from '@/assets/images/trip/date.svg'
import rightArrow from '@/assets/images/right-arrow.svg'
import leftArrow from '@/assets/images/left-arrow.svg'
import './FlatPicker2.scss'
import React, { useState } from 'react'
import { ConvertDateToConvertArray } from '@/utils/convert-date-to-standart/ConvertDateToStandart'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom'

const CustomInput = ({
	value,
	defaultValue,
	inputRef,
	date,
	openCalendar,
	setOpenCalendar,
	calendarRef,
	error,
	setError,
	array,
	...props
}: any) => {
	const errorReset = {
		fromTownCode: false,
		countryCode: false,
		meal_types: false,
		date: false
	}
	const { t } = useTranslation()
	return (
		<div
			className='flatPicker'
			onClick={() => {
				setOpenCalendar(true)
			}}
		>
			<p>{t('departure_date')}</p>
			<p className='search-box-title' ref={inputRef}>
				{date !== null
					? ConvertDateToConvertArray(date, t)
					: t('choose_date')}
				<img src={dateSvg} alt='' />
			</p>

			<div className='search-box-wrapper d-flex'></div>
			{array.length === 0 && (
				<span className='dateNullError'>
					{t('not_info_about_hotel')}
				</span>
			)}
		</div>
	)
}

const CheckDay = ({
	setDateCheckRange,
	dateCheckRange
}: {
	setDateCheckRange: (value: boolean) => void
	dateCheckRange: boolean
}) => {
	const { t } = useTranslation()
	const [localValue, setLocalValue] = useState(dateCheckRange)
	function handler(e: boolean) {
		setDateCheckRange(e)
		setLocalValue(e)
	}
	return (
		<>
			<div className='wrapper_body_fp'>
				<div
					className={
						'input_checkbox ' + (localValue ? 'checked' : '')
					}
					onClick={() => handler(localValue ? false : true)}
				></div>
				<span>+/- 3 {t('day')}</span>
			</div>
		</>
	)
}

const FlatPicker = ({
	array = [],
	openCalendar,
	setOpenCalendar,
	calendarRef,
	date,
	setDate,
	setDateCheckRange,
	dateCheckRange
}: // error,
any) => {
	const [addElement, setAddElement] = useState(false)

	const lang = localStorage.getItem('i18nextLng')
	if (array.length > 0) {
		return (
			<>
				<Flatpickr
					data-enable-time
					value={date}
					onChange={(a: any, value: any) => {
						setDate(value)
						setOpenCalendar(false)
					}}
					onValueUpdate={(selectedDates, dateStr, instance) => {
						if (!addElement) {
							const container = document.createElement('div')
							container.classList.add('wrapperCheckDay')
							const checkDayComponent = (
								<CheckDay
									setDateCheckRange={setDateCheckRange}
									dateCheckRange={dateCheckRange}
								/>
							)
							//@ts-ignore
							const root = ReactDOM.createRoot(container)
							root.render(checkDayComponent)
							instance.calendarContainer.appendChild(container)
							setAddElement(true)
						}
					}}
					options={{
						enable: array,
						enableTime: false,
						locale: lang === 'ru' ? Russian : Latvian,
						showMonths: 1,
						weekNumbers: false,
						shorthandCurrentMonth: false,
						static: true,
						nextArrow: `<img src=${rightArrow} />`,
						prevArrow: `<img src=${leftArrow} />`,
						minDate: 'today'
					}}
					render={(
						{ defaultValue, value, ...props }: any,
						ref: any
					) => {
						return (
							<CustomInput
								defaultValue={defaultValue}
								inputRef={ref}
								date={date}
								array={array}
								openCalendar={openCalendar}
								setOpenCalendar={setOpenCalendar}
								calendarRef={calendarRef}
							/>
						)
					}}
				/>
				<div className='box_vert_line'></div>
			</>
		)
	} else {
		return (
			<>
				<Flatpickr
					data-enable-time
					value={date}
					onChange={(a: any, value: any) => setDate(value)}
					options={{
						enable: [date],
						enableTime: false,
						locale: lang === 'ru' ? Russian : Latvian,
						showMonths: 1,
						weekNumbers: false,
						shorthandCurrentMonth: false,
						static: true,
						nextArrow: `<img src=${rightArrow} />`,
						prevArrow: `<img src=${leftArrow} />`,
						minDate: 'today'
					}}
					render={(
						{ defaultValue, value, ...props }: any,
						ref: any
					) => {
						return (
							<CustomInput
								defaultValue={defaultValue}
								inputRef={ref}
								date={date}
								array={array}
								openCalendar={openCalendar}
								setOpenCalendar={setOpenCalendar}
								calendarRef={calendarRef}
							/>
						)
					}}
				/>
				<div className='box_vert_line'></div>
			</>
		)
	}
}

export default FlatPicker
