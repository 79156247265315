import React from 'react';
import './LoadingPage.scss'
import preloader from './../../assets/images/preloader.png'
import { useTranslation } from 'react-i18next';


const LoadingPage = () => {
    const { t } = useTranslation()
    return (
        <div className='loadingWrapper'>
            <span>{t('Uploading_offers')}</span>
        </div>
    );
};

export default LoadingPage;