import React from 'react'
// import './ReviewSlide.scss'
import s from '../../hotel/Hotel.module.scss'

const ReviewSlide = ({ name, description, date, image }: any) => {
	return (
		<>
			<div className={s.reviews_item}>
				<div className={s.reviews_row_title}>
					<div className={s.reviews_wrapper_icon_name}>
						<div
							className={
								s.trip_user_icon + ' ' + s.trip_user_icon_home
							}
						>
							{image && <img src={image} alt='' />}
						</div>
						<div className={s.reviews_author_published_date}>
							<div className={s.reviews_author}>{name}</div>
							<p className={s.reviews_published_date}>{date}</p>
						</div>
					</div>

					<div className={s.reviews_rating_wrapper}>
						<div>5</div>
						<div className={s.img_star}></div>
					</div>
				</div>
				<div>
					<p className={s.reviews_txt}>{description}</p>
				</div>
			</div>
		</>
	)
}

export default ReviewSlide
