import review1 from './../images/review/review1.png'

export const reviews = [
    {
        name: "Iveta Ručkanova",
        date: "27.08.2023",
        description: `Lieliski organizēts ceļojums šovasar uz Krētu, Rethimno, Bali beach. ☆☆☆☆☆ @Maija Opmane no ceļojumu aģentūras "Mandarina" vienkārši kolosāli visu ieteica un spēja piemeklēt ideālu variantu atbilstoši manām prasībām. Un, lai arī manam  budžetam nebija stingru robežu, tika piemeklēts visatbilstošākais variants, kura rezultātā esmu ļoti, ļoti apmierināta, jo netika iztērēts lieki, kā arī dumji taupīts. 
        Viennozīmīgi iesaku Maiju Opmani!!! Viņa mani gan "sapakoja" ceļojumam, gan "pavadīja ceļā", gan arī ļoti atbildīgi piegāja tam, kā man patīk un kā es jūtos izvēlētajā viesnīcā.
        Jau plānoju nākamo ceļojumu!!! PALDIES! ♡`,
        image: review1
    },
    {
        name: "Aelita Repša",
        date: "31.07.2023",
        description: `Paldies Mandarina trevel par sagatavoto piedāvājumu ceļojumam, kas bij pielāgots mūsu ğimenes vēlmēm! Īpašs paldies ağentei Maijai, par laipnību, pacietību un pretìmnākšanu. Tika sniegti detalizēti argumenti par katru piedāvāto viesnīcu, vēlamajā izmaksu kategorijā, perfekta speciāliste savā jomā!!! Turcijā tika pavadītas fantastiska nedēļa, foršā viesnīcā...Paldies!`,
        image: ''
    },



]