import { axiosClassic } from '@/api/interceptors'
import { PropsSendUs } from './post-query.interface'

export const PostQueryService = {
	async subscribeNews(email: string, isError: boolean) {
		if (!isError) {
			const response = await axiosClassic.post('send-email', { email })
			return response
		}
	},
	async sendUs(form: PropsSendUs) {
		const response = await axiosClassic.post('send-email', form)
		return response
	},
	async contactForm(form: PropsSendUs) {
		const response = await axiosClassic.post('contact-form', form)
		return response
	}
}
