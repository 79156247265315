import axios from "axios"

const  getGoogleReviews=(data:any,setGoogleReviews:any, setGoogleReviewsUrl:any)=>{
    const apiUrl =
			'https://api.mandarina.lv/api/google-reviews?hotelCode=' +
			data.hotelCode
		const config = {
			withCredentials: false
		}
		axios
			.post(apiUrl, '', config)
			.then(response => {
				let reviews=JSON.parse(response.data.reviews);
				setGoogleReviews(reviews)
				setGoogleReviewsUrl(response.data.url)
			})
			.catch(error => {
				console.error('Ошибка при выполнении запроса:', error)
			})
}

export default getGoogleReviews;