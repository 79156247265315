import React from 'react';
import '../paymentSuccessful/paymentSuccessful.scss'
import Header from '../../components/screens/Home/header/Header'
import Footer from '../../components/screens/footer/Footer'
import { Link } from "react-router-dom";
import photo404 from '../../assets/images/photo404.webp'
import { useTranslation } from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className='bg-gray-wrapper'>
                <Header />
            </div>
            <main className='paymentSuccessful'>
                <div className='container-fluid thank_you_container err404'>
                    <div className='row'>
                        <div className='col-12 col-lg-7'>
                            <div className='wrapper_404'>
                                <div className='big_txt number mt140'>
                                    404
                                </div>
                                <div className='big_txt_descript mt20mb50'>
                                    {t('not_found')}
                                </div>
                                <div className='btns_thank_you_wrapper'>
                                    <button className='btn_thank_you fill mr28'>
                                        <Link to='/'>
                                            {t('back_to_home_button')}
                                        </Link>
                                    </button>
                                    <button className='btn_thank_you'>
                                        <Link to={'/contacts'}>
                                            {t('kontakti')}
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-5 err404_photo'
                            style={{ backgroundImage: `url(${photo404})` }}
                        >

                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default NotFound;