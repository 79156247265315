import { ApiData } from '@/api/apiData/api.data'
import CardPopularTour from '@/components/screens/search/card-popular-tour/CardPopularTour'
import React, { FC } from 'react'
import style from './PopularTours.module.scss'
import useBreakpoint from 'use-breakpoint'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 993 }

const PopularTours: FC<any> = ({
	setTimeData,
	timeData,
	getPopularTour,
	subtitles
}) => {
	const countryCode = ApiData.countryCode

	const { breakpoint, maxWidth, minWidth } = useBreakpoint(
		BREAKPOINTS,
		'desktop'
	)

	return (
		<div className={style.popularTours}>
			{breakpoint === 'desktop' ? (
				getPopularTour.map((el: any, key: number) => {
					return (
						<div
							className={`${style.itemCard} 
							${key === 0 && 'row-span-2'} 
							${key === 1 && 'col-span-1 row-span-1'} 
							${key === 3 && 'row-span-1 col-span-1'} 
							${key === 2 && 'row-span-2'} `}
							key={key}
						>
							<CardPopularTour
								data={getPopularTour[key].data?.data}
								subTitle={subtitles[key].subtitle}
								setTimeData={setTimeData}
								timeData={timeData}
								title={countryCode[key]}
								key={1}
							/>
						</div>
					)
				})
			) : (
				<div>
					<Swiper
						modules={[Pagination]}
						pagination={{
							clickable: true,
							dynamicBullets: true,
							dynamicMainBullets: 3
						}}
						spaceBetween={20}
						breakpoints={{
							576: {
								slidesPerView: 1
							},
							768: {
								slidesPerView: 1
							},
							1000: {
								slidesPerView: 2
							}
						}}
					>
						{getPopularTour.map((data: any, key: any) => (
							<SwiperSlide key={key}>
								<CardPopularTour
									data={getPopularTour[key].data?.data}
									subTitle={subtitles[key].subtitle}
									setTimeData={setTimeData}
									timeData={timeData}
									title={countryCode[key]}
									key={1}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			)}
		</div>
	)
}

export default PopularTours
