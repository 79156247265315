import { axiosClassic } from '@/api/interceptors'
import { PropsDateService } from './date.service.interface'

export const DateServiceHotel = {
    async getDate({
                      townFrom,
                      countryCode,
                      adults,
                      childs,
                      childs_age,
                      nights_min,
                      nights_max,
                      price_range_min = 100,
                      price_range_max = 10000,
                      meal_types = ['RO', 'BB', 'HB', 'FB', 'AI', 'UAI'],
                  }: PropsDateService, hotelCode: string | undefined) {
        let countryCodeNew
        hotelCode?.slice(0,2).toLowerCase()=='es' ? countryCodeNew='cn' : countryCodeNew=hotelCode?.slice(0,2).toLowerCase()
        const response = await axiosClassic.get('/date', {
            params: {
                townFrom,
                countryCode: countryCodeNew,
                adults,
                childs,
                childs_age: String(childs_age),
                nights_min,
                nights_max,
                price_range_min,
                price_range_max,
                meal_types: String(meal_types),
                hotelCode
            }
        })
        return response
    }
}