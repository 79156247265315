import AboutTable from '@/templates/about-table/AboutTable'
import React, { FC } from 'react'
import Footer from '../footer/Footer'
import Header from '../Home/header/Header'
import MailingComp from '../Home/mailing-comp/MailingComp'
import { ReturnPolicyData } from './return-policy.data'
import style from './../terms/Terms.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const ReturnPolicy:FC= () => {
	return (
		<>
			<div className='bg-gray-wrapper'>
				<Header/>
			</div>
			<div className='row tab_row'>
				<div className='col-12' style={{paddingRight: "10.5px", paddingLeft: "10.5px"}}>
					<div className={style.content}>
						<h1>Noteikumu par atteikuma tiesību izmantošanu</h1>
						<div>
							<AboutTable>
								<h2>
									<p>1</p>
									<div className={style.liTextPrivacyPolicy}>
										IZMANTOJOT MANDARINA TRAVEL, SIA “ISYTC”,
										REĢISTRĀCIJAS NR. 40103282473, JURIDISKĀ ADRESE
										KATRINAS DAMBIS 24B-12, RĪGA, LV-1045, LATVIJA,
										(TURPMĀK – <strong>MANDARINA.LV</strong>) KĀ STARPNIEKA SNIEGTOS
										PAKALPOJUMUS, JUMS IR TIESĪBAS ATTEIKTIES NO
										PAKALPOJUMA, KURU IEGĀDĀJĀTIES AR MĀJAS LAPAS <Link to={'/'} className={style.link}>WWW.MANDARINA.LV</Link> STARPNIECĪBU ŠĀDOS GADĪJUMOS UN
										KĀRTĪBĀ:
									</div>
								</h2>
								<div>
									<ol>
										<li>
											Klientam ir tiesības atgūt 100% no
											iemaksātās pasūtījuma cenas, ja: Klients ir
											veicis apmaksu par pakalpojumu, bet
											pakalpojums vairs nav pieejams vai to
											iegādājies cits Klients, kura pieprasījums
											jau ticis apstrādāts; Klients veicis
											pakalpojuma priekšapmaksu, bet pakalpojuma
											cenai ir notikusi svārstība – tā ir
											palielinājusies vai samazinājusies;
										</li>
										<li>
											Gadījumā, ja Klients ir veicis pilnu samaksu
											par pakalpojumu, bet ir nolēmis atteikties
											no pakalpojuma un nav piepildījušies
											1.punktā norādītie nosacījumi, atteikuma
											tiesības īstenojamas <strong>saskaņā ar tūrisma
											pakalpojuma sniedzēja noteikumiem</strong> par
											atteikuma tiesību īstenošanu, ar kuriem
											Klients varēs iepazīties, iegādājoties
											attiecīgo tūrisma pakalpojumu.
										</li>
										<li>
											Klientam nav tiesību saņemt atmaksu par
											pakalpojumu, ja Klients patvaļīgi pārtrauc
											tūrisma pakalpojumu vai atsakās no kāda
											apmaksātā papildpakalpojuma tūrisma
											pakalpojuma izmantošanas laikā. Kompensācija
											par neizmantoto pakalpojuma daļu netiek
											atgriezta.
										</li>
										<li>
											Lai izmantotu atteikuma tiesības, Klientam
											ir pienākums informēt par to Mandarina.lv,
											nosūtot rakstveida iesniegumu uz
											Mandarina.lv juridisko adresi vai ē-pasta
											adresi <a className={style.link} href='mailto:info@mandarina.lv'>info@mandarina.lv</a>, izmantojot
											Mandarina.lv <a className={style.link} href='https://www.mandarina.lv/docs/atteikuma-veidlapa.pdf'>atteikuma veidlapu</a> vai
											noformējot iesniegumu brīvā formā, kurā
											iekļauta šāda informācija:
											<br/>Vārds;
											<br/>uzvārds;
											<br/>Adrese;
											<br/>Telefons;
											<br/>Bankas konts;
											<br/>Iegādātais;
											<br/>pakalpojums;
											<br/>Pakalpojuma pilna cena un tās samaksas datums;
											<br/>Atteikuma iemesls.
										</li>
										<li>
											Naudas atgriešana par anulētu tūrisma
											pakalpojumu tiks veikta tādā maksājuma
											formā, kāda izmantota veicot pasūtījumu.
											Nauda tiks atgriezta personai, kas veikusi
											sākotnējo maksājumu uz to norēķinu kontu, no
											kura tika veikts maksājums.
										</li>
										<li>
											Mandarina.lv ir tiesības pārliecināties par
											klienta identitāti, pieprasot iesniegt
											personu apliecinošus dokumentus
										</li>
									</ol>
								</div>
							</AboutTable>
						</div>
					</div>
				</div>
			</div>
			<MailingComp />
			<Footer />
		</>
	)
}

export default ReturnPolicy
