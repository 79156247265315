import Button from '@/components/ui/button/Button'
import { PostQueryService } from '@/services/post-query/PostQuery'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import './MailingComp.scss'
import { trueFn } from 'mini-css-extract-plugin/types/utils'
import useBreakpoint from 'use-breakpoint'

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 992 }

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const MailingComp = () => {
	const { t } = useTranslation()
	const [value, setValue] = useState('')
	const [isInputBlur, setInputBlur] = React.useState(false)
	const [isError, setIsError] = React.useState(false)
	const [buttonClick, setButtonClick] = React.useState(false)
	const sendMailQuery = useMutation(
		'send-mail',
		(mail: string) => PostQueryService.subscribeNews(mail, isError),
		{
			onSuccess: () => {
				if (!isError) {
					toast.success(t('subscribe_text'))
				}
			}
		}
	)
	const onChangeValue = (mail: string) => {
		setValue(mail)
		if (emailRegex.test(mail)) {
			setIsError(false)
		} else {
			setIsError(true)
		}
	}
	const sendMail = (e: any) => {
		e.preventDefault()
		if (emailRegex.test(value)) {
			setIsError(false)
		} else {
			setIsError(true)
			return
		}
		if (sendMailQuery.isLoading || !value) return
		sendMailQuery.mutate(value)
		setButtonClick(!buttonClick)
	}
	const handleBlur = () => {
		setInputBlur(true)
	}
	const handleFocus = () => {
		setInputBlur(false)
	}

	React.useEffect(() => {
		console.log(isInputBlur, 'isBlur')
	}, [isInputBlur])
	return (
		<div className='subscribe_offers_container mt_block'>
			<div className='mailing-comp'>
				<div className='container-xxl'>
					<div className='row subscribe_offers_row'>
						<div className='col-12 col-lg-7'>
							<div className='subscribe_offers_title'>
								{t('get_news_to_your_email')}
							</div>
							<div className='subscribe_offers_descr'>
								{t(
									'great_suggestions_ideas_and_tips_for_a_successful_trip'
								)}
							</div>
						</div>
						<div className='col-12 col-lg-5'>
							<form>
								<div
									className={`subscribe_input_wrap ${
										isError && isInputBlur
											? 'mailErrorError'
											: ''
									}`}
								>
									<input
										type='email'
										name='email'
										value={value}
										onChange={e =>
											onChangeValue(e.target.value)
										}
										placeholder={
											t('your_email') + '*' || ''
										}
										required
										onBlur={handleBlur}
										onFocus={handleFocus}
										className={
											isError && isInputBlur
												? 'mailErrorErrorMobile'
												: ''
										}
									/>
									<button
										type='submit'
										className='hvr-event'
										onClick={e => sendMail(e)}
									>
										{t('subscribe')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className='circle_subsc'></div>
				<div className='circle_subsc2'></div>
				<div className='circle_subsc3'></div>
			</div>
		</div>
	)
}

export default MailingComp
