import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const СomfortModule: FC<any> = ({
	setBeachValue,
	beachValue,
	forKidsValue,
	setForKidsValue,
	forActiveRestValue,
	setForActiveRestValue
}) => {
	const { t } = useTranslation()
	return (
		<>
			<div className='filter_name'>{t('hotel_comfort')}</div>
			{/* <div className='wrapper_checkbox'>
				<input
					id='hotel_comfort-beach'
					style={{ display: 'none' }}
					type='checkbox'
					checked={beachValue}
					onChange={e => {
						setBeachValue(e.target.checked)
					}}
				/>
				<label
					htmlFor='hotel_comfort-beach'
					className='custom-checkbox'
				></label>
				<div className='stars_hotel'>{t('beach')}</div>
			</div> */}
			<div className='wrapper_checkbox'>
				<input
					id='hotel_comfort-kids'
					style={{ display: 'none' }}
					type='checkbox'
					checked={forKidsValue}
					onChange={e => {
						setForKidsValue(e.target.checked)
					}}
				/>
				<label
					htmlFor='hotel_comfort-kids'
					className='custom-checkbox'
				></label>
				<div className='stars_hotel'>{t('for_kids')}</div>
			</div>

			<div className='wrapper_checkbox'>
				<input
					id='hotel_comfort-active_rest'
					style={{ display: 'none' }}
					type='checkbox'
					checked={forActiveRestValue}
					onChange={e => {
						setForActiveRestValue(e.target.checked)
					}}
				/>
				<label
					htmlFor='hotel_comfort-active_rest'
					className='custom-checkbox'
				></label>
				<div className='stars_hotel'>{t('for_active_rest')}</div>
			</div>
		</>
	)
}

export default СomfortModule
