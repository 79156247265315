import React from 'react';
import './paymentSuccessful.scss'
import Header from "../../components/screens/Home/header/Header";
import Footer from "../../components/screens/footer/Footer"
import thankyouphoto from './../../assets/images/thankyouphoto.jpg'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";


const PaymentSuccessful = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className='bg-gray-wrapper'>
                <Header />
            </div>
            <main className='paymentSuccessful'>
                <div className='container-fluid thank_you_container'>
                    <div className='row'>
                        <div
                            className='col-12 col-lg-5 thank_you_photo'
                            style={{ backgroundImage: `url(${thankyouphoto})` }}
                        >
                        </div>
                        <div className='col-12 col-lg-7'>
                            <div className='big_txt mt140'>
                                {t('thank_you_for_order')}
                            </div>
                            <div className='big_txt_descript mt20mb50'>
                                {t('thank_you_for_order_text')}
                            </div>
                            <div className='btns_thank_you_wrapper'>
                                <button className='btn_thank_you fill mr28'>
                                    <Link to='/'>
                                        {t('back_to_home_button')}
                                    </Link>
                                </button>
                                <button className='btn_thank_you'>
                                    <Link to={'/contacts'}>
                                        {t('kontakti')}
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default PaymentSuccessful;