import React, { FC, useState } from 'react'
import s from './Hotel.module.scss'
import { useTranslation } from 'react-i18next'
interface DetailedRatingsItem {
	code: string
	rating: number
}

const DetailedRatings: FC<any> = ({ detailed_ratings }) => {
	const { t } = useTranslation()
	return (
		<>
			<div className={s.detailed_ratings_wrapper}>
				<p className={s.detailed_ratings_title}>{t('guests_mark')}</p>

				<div className={s.detailed_ratings_wrapper_items}>
					{detailed_ratings.map((el: DetailedRatingsItem) => (
						<div className={s.detailed_ratings_item} key={el.code}>
							<p className={s.detailed_ratings_name_line}>
								{t(el.code)}
							</p>
							<div className={s.detailed_ratings_line}>
								<div
									className={s.detailed_ratings_line_value}
									style={{ width: el.rating * 20 + '%' }}
								></div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	)
}

export default DetailedRatings
