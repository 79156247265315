import FlatPick from './flatpick/FlatPick'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './ContactForm.module.scss'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const phoneRegex = /^[0-9+-]+$/

const ContactFormMain: FC<any> = ({
	index,
	value,
	setValue,
	error,
	resetError,
	setFormInfoError
}) => {
	const { t } = useTranslation()
	const currentDate = new Date()
	currentDate.setFullYear(currentDate.getFullYear() - 18)
	const year = currentDate.getFullYear()
	const month = String(currentDate.getMonth() + 1).padStart(2, '0')
	const day = String(currentDate.getDate()).padStart(2, '0')
	const formattedDate = `${year}-${month}-${day}`

	function validationEmail(e: React.ChangeEvent<HTMLInputElement>) {
		setValue(e.target.value, index, 'email')
		const newError = [...error]
		if (emailRegex.test(e.target.value)) {
			newError[0]['email'] = false
		} else {
			newError[0]['email'] = true
		}
		setFormInfoError(newError)
	}
	function validationPhone(e: React.ChangeEvent<HTMLInputElement>) {
		setValue(e.target.value, index, 'phone')
		const newError = [...error]
		if (phoneRegex.test(e.target.value)) {
			newError[0]['phone'] = false
		} else {
			newError[0]['phone'] = true
		}
		setFormInfoError(newError)
	}
	return (
		<div className={style.form} onClick={() => resetError()}>
			<h2>
				{t('contact_information')} №{index + 1}
			</h2>
			<form>
				<div>
					<label>{t('firstname')}</label>
					<input
						type='text'
						value={value.firstName}
						onChange={e =>
							setValue(e.target.value, index, 'firstName')
						}
						className={error[index].firstName && 'emailError'}
					/>
				</div>
				<div>
					<label>{t('lastname')}</label>
					<input
						type='text'
						value={value.lastName}
						onChange={e =>
							setValue(e.target.value, index, 'lastName')
						}
						className={error[index].lastName && 'emailError'}
					/>
				</div>
				<div>
					<label>{t('adres_email')}</label>
					<input
						type='email'
						value={value.email}
						autoComplete='on'
						name='email'
						onChange={e => validationEmail(e)}
						className={error[index].email && 'emailError'}
					/>
				</div>
				<div>
					<label>{t('phone_number')}</label>
					<input
						type='tel'
						value={value.phone}
						onChange={e => validationPhone(e)}
						className={error[index].phone && 'emailError'}
					/>
				</div>
				<div>
					<label>{t('date_of_birth')}</label>
					<input
						className={
							style.w100_webkit +
							' ' +
							(error[index].date && 'emailError')
						}
						type='date'
						value={value.date}
						max={formattedDate}
						onChange={e => setValue(e.target.value, index, 'date')}
					/>
					{/* <FlatPick calendarRef={calendarRef}
						date={date}
						setDate={setDate}
						// setState={setState}
						/> */}
				</div>
			</form>
		</div>
	)
}

export default ContactFormMain
