import 'flatpickr/dist/themes/material_blue.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { Latvian } from "flatpickr/dist/l10n/lv.js";
import ReactDOM from 'react-dom';

import Flatpickr from 'react-flatpickr'

import icon from '@/assets/images/3.svg'
import rightArrow from '@/assets/images/right-arrow.svg'
import leftArrow from '@/assets/images/left-arrow.svg'

import './FlatPicker.scss'
import { ConvertDateToConvertArray } from '@/utils/convert-date-to-standart/ConvertDateToStandart'
import { useTranslation } from 'react-i18next'
import { useState } from 'react';

const CustomInput = ({
	inputRef,
	date,
	setOpenCalendar,
	calendarRef,
	error,
	setError,
	...props
}) => {
	const { t } = useTranslation()
	const errorReset = {
		fromTownCode: false,
		countryCode: false,
		meal_types: false,
		date: false
	}
	return (
		<div
			className='search-box flatpickr-custom-input'
			ref={inputRef}
			onClick={() => {
				setOpenCalendar(true)
				setError(error => ({ ...error, ...errorReset }))
			}}
		>
			<p className='search-box-title' ref={calendarRef}>
				{t('date')}
			</p>
			<div className='search-box-wrapper d-flex'>
				<img src={icon} alt='' />
				{date !== null ? (
					<p className='search-box-input'>{ConvertDateToConvertArray(date, t)}</p>
				) : (
					<p className='search-box-input'>{t('date')}</p>
				)}
			</div>
		</div>
	)
}
const CheckDay = ({ setDateCheckRange, dateCheckRange }) => {
	const [localValue, setLocalValue] = useState(dateCheckRange);
	function handler(e) {
		setDateCheckRange(e)
		setLocalValue(e)
	}
	const { t } = useTranslation()
	return (
		<>
			<div className='wrapper_body_fp'>
				<div className={'input_checkbox ' + (localValue ? 'checked' : '')} onClick={() => handler(localValue ? false : true)}></div>
				<span>+/- 3 {t('day')}</span>
			</div>
		</>
	)
}
const FlatPicker = ({
	array,
	openCalendar,
	setOpenCalendar,
	calendarRef,
	date,
	setDate,
	error,
	setError,
	setDateCheckRange,
	dateCheckRange
}) => {
	const [addElement, setAddElement] = useState(false);

	const lang = localStorage.getItem('i18nextLng')
	if (array.length > 0) {
		return (
			<>
				<Flatpickr
					data-enable-time
					onChange={(a, value) => { setDate(value); setOpenCalendar(false); }}
					onValueUpdate={(selectedDates, dateStr, instance) => {
						if (!addElement) {
							const container = document.createElement('div');
							container.classList.add('wrapperCheckDay');
							const checkDayComponent = <CheckDay setDateCheckRange={setDateCheckRange} dateCheckRange={dateCheckRange} />;
							const root = ReactDOM.createRoot(container);
							root.render(checkDayComponent);
							instance.calendarContainer.appendChild(container);
							setAddElement(true)
						}
					}}
					options={{
						enable: array,
						enableTime: false,
						locale: lang === 'ru' ? Russian : Latvian,
						showMonths: 1,
						weekNumbers: false,
						shorthandCurrentMonth: false,
						static: true,
						nextArrow: `<img src=${rightArrow} />`,
						prevArrow: `<img src=${leftArrow} />`,
						minDate: "today",
					}}
					value={date}
					render={({ defaultValue, value, ...props }, ref) => {
						return (
							<>
								<CustomInput
									inputRef={ref}
									date={date}
									setOpenCalendar={setOpenCalendar}
									calendarRef={calendarRef}
									error={error}
									setError={setError}
								/>
							</>
						)
					}}
				/>
				<div className='box_vert_line'></div>
			</>
		)
	}
	else {
		return (
			<>
				<Flatpickr
					data-enable-time
					onChange={(a, value) => setDate(value)}
					options={{
						enable: [date],
						enableTime: false,
						defaultDate: date,
						value: date,
						locale: lang === 'ru' ? Russian : Latvian,
						showMonths: 1,
						weekNumbers: false,
						shorthandCurrentMonth: false,
						static: true,
						nextArrow: `<img src=${rightArrow} />`,
						prevArrow: `<img src=${leftArrow} />`,
						minDate: 'today'
					}}
					render={({ defaultValue, value, ...props }, ref) => {
						return (
							<CustomInput
								defaultValue={defaultValue}
								value={value}
								inputRef={ref}
								date={date}
								openCalendar={openCalendar}
								setOpenCalendar={setOpenCalendar}
								calendarRef={calendarRef}
								error={error}
								setError={setError}
							/>
						)
					}}
				/>
				<div className='box_vert_line'></div>
			</>
		)
	}
}

export default FlatPicker
