import AboutTable from '@/templates/about-table/AboutTable'
import React, { FC } from 'react'
import Footer from '../footer/Footer'
import Header from '../Home/header/Header'
import MailingComp from '../Home/mailing-comp/MailingComp'
import style from './../terms/Terms.module.scss'
import { Link } from 'react-router-dom'

const PrivacyPolicy: FC = () => {
	return (
		<>
			<div className='bg-gray-wrapper'>
				<Header />
			</div>
			<div className='row tab_row'>
				<div
					className='col-12'
					style={{ paddingRight: '10.5px', paddingLeft: '10.5px' }}
				>
					<div className={style.content}>
						<h1>SIA “iSYTC” Privātuma politika</h1>
						<div>
							<AboutTable>
								<h2>
									<p>1</p>
									I. VISPĀRĒJĀ DAĻA
								</h2>
								<div>
									<ol>
										<li>
											SIA “iSYTC”, reģistrēts Latvijas
											Republikas Uzņēmumu reģistrā ar
											reģistrācijas Nr. 40103282473,
											juridiskā adrese Katrīnas dambis 24B
											- 12, Rīga, LV-1045, Latvija,
											(turpmāk – Mandarina.lv), ir
											pārzinis, kas izpilda Eiropas
											Savienības Vispārīgās datu
											aizsardzības regulas Nr.2016/679
											(turpmāk – Regula) prasības Latvijā.
											Šī Privātuma politika izskaidro, kā
											Mandarina.lv apstrādā personas
											datus, kad tiek izmantoti mūsu, kā
											starpnieka, nodrošinātie
											pakalpojumi. Šī politika attiecas uz
											visa veida personas datu apstrādi,
											ko Mandarina.lv iegūst no interneta
											vietnes{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											, e-pastu sarakstes, aplikācijām,
											logrīkiem, anketām, līgumiem un
											citiem informācijas apmaiņas
											līdzekļiem.
										</li>
									</ol>
								</div>
								<h2>
									<p>2</p>
									II. PERSONAS DATU IEVĀKŠANA UN IZMANTOŠANA
								</h2>
								<div>
									<ol>
										<li>
											Neatkarīgi no kuras valsts Jūs
											piekļūstat Mandarina.lv
											pakalpojumiem, personas datu
											apstrādē tiek ievēroti Regulas
											noteikumi. Jūs esat informēti, ka
											Jūsu personas dati Latvijā var tikt
											apstrādāti arī uz šīs valsts tiesību
											aktu pamata.
										</li>
										<li>
											Mandarina.lv informē, ka, norādot
											savu e-pasta adresi vai tālruni,
											piekrītat, ka tā tiek iekļauta datu
											bāzē un uz to var tikt nosūtīti
											paziņojumi/atgādinājumi
										</li>
										<li>
											Jūs esat informēts, ka, lietojot
											interneta vietni{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											, Mandarina.lv vai jebkura trešā
											persona, kas darbojas Mandarina.lv
											vārdā, var apkopot un uzglabāt
											datus, kas ļauj izsekot un
											uzskaitīt:
										</li>
										<li>
											Tīmekļa vietņu kopējo apmeklējumu
											skaitu;
										</li>
										<li>
											katras konkrētās tīmekļa vietnes
											apmeklētāju skaitu;
										</li>
										<li>
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>{' '}
											apmeklētāju interneta pakalpojumu
											sniedzēju domēna vārdus;
										</li>
										<li>IP adreses;</li>
										<li>
											Citus datus, kuru izmantošanas
											mērķis ir sistēmu administrēšana,{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>{' '}
											interneta vietnes lietošanas
											kontrole un tās uzlabošana.
										</li>
									</ol>
								</div>

								<h2>
									<p>3</p>
									III. PERSONAS DATU APSTRĀDES MĒRĶI UN
									TIESISKIE PAMATI
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv apstrādā personas datus
											galvenokārt šādiem mērķiem:
										</li>
										<li>
											Pakalpojumu un distances līguma,
											rēķinu sagatavošana, noslēgšana un
											izsūtīšana
										</li>
										<li>
											Efektīvu uzņēmuma pārvaldības
											procesu nodrošināšana
										</li>
										<li>Biznesa plānošana un analītika</li>
										<li>Personāla atlase un vadība</li>
										<li>
											Pieteikumu izskatīšana un apstrāde
										</li>
										<li>Jaunumu izsūtīšana</li>
										<li>Saziņa ar klientu</li>
										<li>
											Personas datu nodošana tūrisma
											operatoriem un/vai tūrisma
											pakalpojumu sniedzējiem
										</li>
										<li>
											Mandarina.lv apstrādā personas datus
											uz šādiem tiesiskajiem pamatiem:
										</li>
										<li>
											Ar normatīvo aktu noteikta juridiskā
											pienākuma izpilde
										</li>
										<li>
											Leģitīmās intereses nodrošināšanai
										</li>
										<li>Līguma noslēgšanai vai izpildei</li>
										<li>Datu subjekta piekrišana</li>
									</ol>
								</div>
								<h2>
									<p>4</p>
									IV. PERSONAS DATU GLABĀŠANAS ILGUMS
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv glabā personas datus
											atbilstoši definētajiem personas
											datu apstrādes mērķiem un normatīvo
											aktu prasībām, kamēr pastāv vismaz
											viens no šiem kritērijiem:
										</li>
										<li>
											Kamēr ārējos normatīvajos aktos
											noteiktajā kārtībā Mandarina.lv var
											realizēt savas leģitīmās intereses;
										</li>
										<li>
											Kamēr Mandarina.lv pastāv juridisks
											pienākums datus glabāt saskaņā ar
											normatīvajiem aktiem;
										</li>
										<li>
											Kamēr spēkā ir datu subjekta
											piekrišana attiecīgai personas datu
											apstrādei, ja nepastāv cits datu
											apstrādes likumīgs pamats.
										</li>
									</ol>
								</div>
								<h2>
									<p>5</p>
									V. SĪKDATNES
								</h2>
								<div>
									<ol>
										<li>
											Sīkdatnes ir teksta datne, kas tiek
											ievietota mājaslapas lietotāja
											datora pārlūkprogrammā, lai uzlabotu
											mājaslapas darbību. Saglabātas
											sīkdatnes ļauj atpazīt Jūsu datoru.
											Atkārtoti apmeklējot Mandarina.lv
											mājaslapu un izmantojot tikai ar
											paroli pieejamas mājaslapas sadaļas,
											sīkdatnes ļauj izvairīties no
											atkārtotas datu ievades.
										</li>
										<li>
											Sīkdatnes tiek izmantotas šādiem
											mērķiem:
										</li>
										<li>
											sesiju pārvaldībai un lietotāju
											autentifikācijai;
										</li>
										<li>
											mājaslapas funkcionalitātes
											nodrošināšanai;
										</li>
										<li>
											statistikas datu apkopošana par
											lapas apmeklētāju plūsmu –
											apmeklētāju skaits, lapā pavadītais
											laiks;
										</li>
										<li>
											vietnes darbības efektivitātes
											uzlabošanai.
										</li>
										<li>
											Sīkdatņu ietvaros veiktās personas
											datu apstrādes tiesiskais pamats ir
											Mandarina.lv leģitīmā interese.
										</li>
										<li>
											Apmeklējot mājaslapu{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
											, var tikt izmantotas sīkdatnes, par
											ko informējam mājaslapas apmeklējuma
											laikā.
										</li>
										<li>
											Sīkdatnes ļauj noteikt
											vispieprasītākās mājaslapas daļas
											(kuras mājaslapas daļas un cik ilgi
											apmeklētāji tās apmeklē).
										</li>
										<li>
											Ja vien normatīvajos aktos nav
											paredzēts citādāk, personai jebkurā
											laikā ir tiesības atteikties no
											turpmākas savu datu apstrādes, taču
											tādā gadījumā, īpaši, ja minētie
											dati ir tehniski būtiski, pastāv
											iespēja, ka pakalpojumu
											nodrošināšanas kvalitāte var
											pasliktināties vai nebūt pieejama
											vispār.
										</li>
									</ol>
								</div>
								<h2>
									<p>6</p>
									VI. SĪKDATŅU KONTROLE
								</h2>
								<div>
									<ol>
										<li>
											Datu subjekts var dzēst sīkdatnes
											pēc savas izvēles (sīkāka
											informācija –{' '}
											<a
												className={style.link}
												href='https://www.aboutcookies.org/'
											>
												https://www.aboutcookies.org/
											</a>{' '}
											).
										</li>
										<li>
											Jūs varat dzēst visas sīkdatnes, kas
											jau ir saglabātas Jūsu datorā, un
											var noteikt, lai pārlūkprogramma
											neļauj tās saglabāt. Tādā gadījumā
											katru reizi nepieciešams manuāli
											norādīt savu izvēli, kad apmeklējat
											vietni; daži pakalpojumi un
											interneta vietnes funkcijas var
											nedarboties.
										</li>
									</ol>
								</div>
								<h2>
									<p>7</p>
									VII. PERSONAS DATU AIZSARDZĪBA
								</h2>
								<div>
									<ol>
										<li>
											GoTrip aizsargā personu datus,
											izmantojot mūsdienu tehnoloģiju
											iespējas, ņemot vērā pastāvošos
											privātuma riskus, veicot drošības
											pasākumus un veicot šādus drošības
											risinājumus:
										</li>
										<li>ugunsmūris;</li>
										<li>
											datu šifrēšana, pārraidot datus (SSL
											šifrēšana);
										</li>
										<li>
											citus aizsardzības pasākumus
											atbilstoši aktuālajām tehnoloģiju
											attīstības iespējām.
										</li>
									</ol>
								</div>
								<h2>
									<p>8</p>
									VIII. PERSONAS DATU SAŅĒMĒJI
								</h2>
								<div>
									<ol>
										<li>
											Ņemot vērā, ka Mandarina.lv darbojas
											kā piegādātājs jeb starpnieks starp
											klientiem un tūrisma pakalpojuma
											sniedzējiem, Mandarina.lv nodos datu
											subjekta personas datus attiecīgajam
											tūrisma operatoram vai tūrisma
											pakalpojuma sniedzējiem, kuri sniegs
											datu subjekta izvēlēto pakalpojumu,
											tādā apjomā, kāds ir nepieciešams
											pakalpojuma saņemšanai un
											nodrošināšanai, tie var būt,
											piemēram, vārds, uzvārds, dzimšanas
											datums, pilsonība, pases numurs,
											pases derīguma termiņš, personas
											kods, e-pasts, tālrunis, deklarētā
											dzīvesvietas adrese. Nododamo datu
											apjoms var atšķirties no tūrisma
											pakalpojuma sniedzējam nepieciešamās
											informācijas, kā arī no prasībām,
											kuras ir noteiktas tūrisma
											pakalpojuma sniedzēja mītnes valsts
											tiesību aktos.
										</li>
										<li>
											Mandarina.lv apliecina, ka tūrisma
											pakalpojuma sniedzējiem netiks
											nodoti tādi personas dati, kuri nav
											nepieciešami pakalpojuma saņemšanai
											un nodrošināšanai.
										</li>
										<li>
											Likumā noteiktos gadījumos
											Mandarina.lv var nodot informāciju
											trešajām personām. Tāpat
											Mandarina.lv var izmantot
											ārpakalpojumus, lai nodrošinātu
											efektīvāku savu funkciju veikšanu.
											Mandarina.lv ir tiesības sazināties
											ar personu, kura ir atbildīga par
											pakalpojuma rezervāciju,
											organizēšanu vai ir tiešais
											pakalpojuma saņēmējs, izmantojot
											norādīto e-pastu, tālruni vai citu
											saziņas rīku.
										</li>
										<li>
											Mandarina.lv interneta vietnē{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>{' '}
											apkopotos un uzkrātos datus
											informatīvos nolūkos un statistikas
											veikšanas nolūkos ir tiesīga nodot
											arī citiem uzņēmumiem. Dati, kas
											ļautu identificēt personu netiek
											atklāti un nodoti trešajām personām
										</li>
									</ol>
								</div>
								<h2>
									<p>9</p>
									IX. PIEKĻUVE SAVIEM PERSONAS DATIEM UN CITAS
									DATU SUBJEKTA TIESĪBAS
								</h2>
								<div>
									<ol>
										<li>
											Lai saņemtu informāciju par personas
											datiem, kurus Mandarina.lv par Jums
											apstrādā, Jums ir jānosūta
											rakstveida iesniegums, adresējot tos
											šīs{' '}
											<Link
												to={'/privacy-policy'}
												className={style.link}
											>
												Privātuma politikas
											</Link>{' '}
											1.1. punktā norādītajai juridiskajai
											personai uz tās juridisko adresi.
											Pirms iesnieguma nosūtīšanas
											personas pienākums ir pārliecināties
											par to, vai Mandarina.lv juridiskā
											adrese nav mainīta, ko var pārbaudīt
											internetvietnes{' '}
											<a
												href='https://www.lursoft.lv/'
												className={style.link}
											>
												www.lursoft.lv
											</a>{' '}
											datu bāzē.
										</li>
										<li>
											Datu subjektam ir tiesības pieprasīt
											piekļuvi saviem datiem, to labošanu
											vai nepieciešamības gadījumā
											papildināšanu, dzēšanu (tiesības
											“tikt aizmirstam”), apstrādes
											ierobežošanu, vai iebilst pret to
											apstrādi normatīvajos aktos
											noteiktajos gadījumos, kā arī
											tiesības uz datu pārnesamību.
											Informējam, ka datu labošanas,
											dzēšanas, ierobežošanas,
											pārtraukšanas vai pārnesamības
											gadījumā, ir iespējama daļēja vai
											pilnīga pakalpojumu/procesu
											apturēšana neatgriezeniskā veidā.
											Izvēloties saņemt informāciju par
											sevi attālināti, piemēram, pa pastu,
											e-pastu, uz citu adresātu vai tml.,
											iesniedzējs ir atbildīgs par
											izvēlētā saņemšanas veida drošumu un
											personu, kas darbojas iesniedzēja
											pārstāvībā, rīcību.
										</li>
										<li>
											Mandarina.lv veic saziņu ar datu
											subjektu, izmantojot datu subjekta
											norādīto kontaktinformāciju
											(telefona numuru, e-pasta adresi,
											pasta adresi).
										</li>
										<li>
											Ja Jums šķiet, ka Jūsu tiesības uz
											personas datu aizsardzību ir
											pārkāptas, Jūs varat iesniegt
											sūdzību personas datu uzraudzības
											iestādei.
										</li>
									</ol>
								</div>
								<h2>
									<p>10</p>
									X. TIESĪBAS ATSAUKT PIEKRIŠANU
								</h2>
								<div>
									<ol>
										<li>
											Ja personas datu apstrāde ir uzsākta
											un notiek saskaņā ar datu subjekta
											piekrišanu, datu subjektam ir
											tiesības to atsaukt, sūtot atsaukumu
											ievērojot šīs{' '}
											<Link
												to={'/privacy-policy'}
												className={style.link}
											>
												Privātuma politikas
											</Link>
											&nbsp; 9.1.punktā noteikto kārtību.
										</li>
										<li>
											Piekrišanas atsaukums neietekmē datu
											apstrādes, kuras veiktas tajā laikā,
											kad klienta piekrišana bija spēkā.
											Atsaucot piekrišanu, nevar tikt
											pārtraukta datu apstrāde, kuru veic,
											pamatojoties uz citiem tiesiskajiem
											pamatiem.
										</li>
									</ol>
								</div>
								<h2>
									<p>11</p>
									XI. IZMAIŅAS PRIVĀTUMA POLITIKĀ
								</h2>
								<div>
									<ol>
										<li>
											Mandarina.lv ir tiesības jebkurā
											brīdī izdarīt izmaiņas, kuras tiek
											publicētas interneta vietnē{' '}
											<Link
												to={'/'}
												className={style.link}
											>
												www.mandarina.lv
											</Link>
										</li>
									</ol>
								</div>
							</AboutTable>
						</div>
					</div>
				</div>
			</div>
			<MailingComp />
			<Footer />
		</>
	)
}

export default PrivacyPolicy
