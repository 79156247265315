import { SearchToursService } from '@/services/search-tours/SearchToursService.service'
import SearchBox from '@/templates/main-form/SearchBox'
import Input from '@/ui/input/Input'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import GetOfferButton from '../ui/get-offer-button/GetOfferButton'
import GetOfferSearchBox from '../ui/get-offer-search-box/GetOfferSearchBox'
import { PropsGetOfferState } from './get-offer6.interface'
import style from './GetOffer6.module.scss'
import { log } from 'console'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const GetOffer6: FC<PropsGetOfferState> = ({ state, setState }) => {
	const [id, setId] = useState<any>()
	const [modalOpen, setModalOpen] = React.useState(false)
	const { t } = useTranslation()
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language
	const getOffer = useMutation(
		'get-offer',
		(data: any) => SearchToursService.getOffer(data),
		{
			onSuccess: () => {
				//alert('all successfully')
				// setModalOpen(true)
			}
		}
	)
	const getOffer_ru = useMutation(
		'get-offer',
		(data: any) => SearchToursService.getOffer_ru(data),
		{
			onSuccess: () => {
				//alert('all successfully')
				// setModalOpen(true)
			}
		}
	)
	const [error, setError] = useState({
		firstName: false,
		lastName: false,
		phone: false,
		email: false
	})
	const [isEmailValid, setEmailValid] = React.useState(true)
	const [isMailBlur, setMailBlur] = React.useState(false)

	const onChangeEmail = (e: any) => {
		setError(error => ({ ...error, email: false }))
		setState(state => ({
			...state,
			form: {
				...state.form,
				email: e.target.value
			}
		}))
		console.log(state.form.email, 'state')
		console.log(isMailBlur, 'isEmailBlur')
		let emailForm = state.form.email
		if (emailRegex.test(emailForm) === true) {
			setEmailValid(true)
		} else {
			setEmailValid(false)
		}
	}

	const handleBlurMail = () => {
		setMailBlur(false)
		console.log('blur', isMailBlur)
	}
	const handleFocusMail = () => {
		setMailBlur(true)
		console.log('focus', isMailBlur)
	}

	const handlerClick = () => {
		let newError = { ...error }
		let flag = false
		Object.keys(state.form).map(el => {
			//@ts-ignore
			if (!state.form[el]) {
				//@ts-ignore
				newError[el] = true
				flag = true
			}
		})
		if (flag) {
			if (newError?.firstName) {
				toast.error(t('formInfoError_firstName'))
				return
			}
			if (newError?.lastName) {
				toast.error(t('formInfoError_lastName'))
				return
			}
			if (newError?.phone) {
				toast.error(t('formInfoError_phone'))
				return
			}
			if (newError?.email) {
				toast.error(t('formInfoError_email'))
				return
			}
			return setError(newError)
		}
		const date = {
			month: state.month,
			date: state.month,
			country: state.countryCode,
			peopleAdults: state.adults,
			peopleСhildren: state.childs,
			priceRange: `${state.price_range_min} - ${state.price_range_max}`,
			email: state.form.email,
			phone: state.form.phone,
			peopleBaby: state.peopleBaby,
			first_name: state.form.firstName,
			last_name: state.form.lastName,
			comment: state.comment
		}
		if (isEmailValid === true) {
			currentLanguage == 'lv'
				? getOffer.mutate(date)
				: getOffer_ru.mutate(date)
			setModalOpen(true)
		}
	}
	return (
		<div className={style.getOffer6}>
			{modalOpen && (
				<div className={style.modalWrapper}>
					<div className={style.modal}>
						<p className={style.text}>{t('address_text')}</p>
						<button
							className={style.button}
							onClick={() => {
								setModalOpen(false)
								setState({
									step: 1,
									countryCode: '',
									month: '',
									childs: 0,
									chidls_age: [],
									adults: 1,
									comment: '',
									form: {
										firstName: '',
										lastName: '',
										phone: '',
										email: ''
									},
									peopleBaby: 0,
									price_range_min: null,
									price_range_max: null
								})
							}}
						>
							Ок
						</button>
					</div>
				</div>
			)}
			<div className={style.header}>
				<h1>{t('contacts')}</h1>
				<h2>
					{t(
						'leave_your_contact_information_so_that_we_can_contact_you'
					)}
				</h2>
			</div>
			<form className={style.form}>
				<div className={style.contInput}>
					<label>{t('firstname')}</label>
					<input
						type='text'
						placeholder={t('enter_your_name_here') || ''}
						value={state.form.firstName}
						onChange={e => {
							setError(error => ({ ...error, firstName: false }))
							setState(state => ({
								...state,
								form: {
									...state.form,
									firstName: e.target.value
								}
							}))
						}}
					/>
				</div>
				<div className={style.contInput}>
					<label>{t('last_name')}</label>
					<input
						type='text'
						placeholder={t('enter_your_last_name_here') || ''}
						value={state.form.lastName}
						onChange={e => {
							setError(error => ({ ...error, lastName: false }))
							setState(state => ({
								...state,
								form: {
									...state.form,
									lastName: e.target.value
								}
							}))
						}}
					/>
				</div>
				<div className={style.contInput}>
					<label>{t('phone')}</label>
					<input
						type='text'
						placeholder={t('enter_your_phone_number_here') || ''}
						value={state.form.phone}
						onChange={e => {
							setError(error => ({ ...error, phone: false }))
							setState(state => ({
								...state,
								form: {
									...state.form,
									phone: e.target.value
								}
							}))
						}}
					/>
				</div>
				<div className={style.contInput}>
					<label>{t('email')}</label>
					<input
						type='email'
						placeholder={t('enter_your_email_address_here') || ''}
						required={true}
						value={state.form.email}
						onChange={e => onChangeEmail(e)}
						onFocus={handleFocusMail}
						onBlur={handleBlurMail}
						className={
							!isMailBlur && !isEmailValid ? 'emailError' : ''
						}
					/>
				</div>
			</form>
			<GetOfferButton
				disabled={
					currentLanguage == 'lv'
						? getOffer.isLoading
						: getOffer_ru.isLoading
				}
				onClick={() => handlerClick()}
			>
				{t('get_offer')}
			</GetOfferButton>
		</div>
	)
}

export default GetOffer6
