import React, { FC } from 'react'
import defaultImg from '@/assets/images/default-home.svg'
import style from './CardPopularTour.module.scss'
import Button from '../../../ui/button/Button'
import { PropsCardPopularTour } from './card-popular-tour.interface'
import { IoIosArrowForward } from 'react-icons/io'
import { RevertCountryCode } from '@/utils/revert-countryCode/RevertCountryCode'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import gr from './../../../../assets/images/destinations/GR.webp'
import tr from './../../../../assets/images/destinations/TR.webp'
import eg from './../../../../assets/images/destinations/EG.webp'
import sp from './../../../../assets/images/destinations/CN.webp'
import me from './../../../../assets/images/destinations/ME.webp'

const currentImage = (title: string) => {
	if (title === 'gr') {
		return gr
	} else if (title === 'tr') {
		return tr
	} else if (title === 'eg') {
		return eg
	} else if (title === 'cn') {
		return sp
	} else if (title === 'me') {
		return me
	} else {
		return defaultImg
	}
}

const CardPopularTour: FC<PropsCardPopularTour> = ({
	subTitle,
	data,
	setTimeData,
	timeData,
	title
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const handlerClick = () => {
		const newArray = {
			adult: 2,
			child: 0,
			childs_age: [],
			countryCode: title,
			data: data.date,
			meal_types: ['RO', 'BB', 'HB', 'FB', 'AI', 'UAI'],
			nights_max: 7,
			nights_min: 5,
			price_range_max: 10000,
			price_range_min: 100,
			townFrom: timeData.townFrom || 'lv',
			rating: [true, true, true, true, true]
		}
		localStorage.setItem('userInfo', JSON.stringify(newArray))
		setTimeData((state: any) => ({ ...state, ...newArray }))
		navigate('/search-tours')
	}
	return (
		<div className={style.popularTour}>
			<img
				src={currentImage(title)}
				alt='popular-tour'
				className={style.bgImage}
			/>
			<div className={style.header}>
				<h2>{t(RevertCountryCode(title))}</h2>
				<p>{subTitle}</p>
			</div>
			<div className={style.footer}>
				<p>{t('two_adults')}</p>
				<div className={style.footerButton}>
					<p>
						{data?.price != '0' && t('c')}{' '}
						<span>
							{data?.price == '0'
								? t('not_have_offers')
								: data?.price + ' €'}{' '}
						</span>
					</p>
					<Button
						className={style.button}
						onClick={() => handlerClick()}
					>
						{data?.countHotel} {t('hotels_v2')}{' '}
						<IoIosArrowForward></IoIosArrowForward>{' '}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default CardPopularTour
