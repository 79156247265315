import React, { FC, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import hotelStar from '@/assets/images/hotel-star.svg'
import defaultPhoto from '@/assets/images/default-home.svg'
import './SearchPageHotelCard.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import getGoogleRating from '@/components/Request/GoogleRating'

const SearchPageHotelCard: FC<any> = ({ hotel }) => {
	const [googleRating, setGoogleRating] = useState('')

	useEffect(() => {
		getGoogleRating(hotel, setGoogleRating)
	}, [hotel])

	const navigate = useNavigate()
	const { t } = useTranslation()
	return (
		<div className='col-12 col-lg-6 search-page-hotel-card'>
			<div className='slideComp'>
				<Link
					to={`/hotel/${hotel.hotelCode}`}
					state={{
						fixPrice: hotel.price
					}}
				>
					<div className='image-slider-wrapper'>
						<div className='wrapper_ta_rating_image'>
							{googleRating != '' && (
								<div className='google_rating ta_rating_image'>
									<span>{googleRating}/5</span>
								</div>
							)}
							{hotel.ta_rating_image_url && (
								<div className='ta_rating_image'>
									<span>{hotel.ta_rating}/5</span>
								</div>
							)}
						</div>

						<Swiper
							navigation={true}
							modules={[Navigation, Thumbs]}
							scrollbar={{ draggable: true }}
							allowTouchMove={false}
						>
							{hotel?.photoList.length ? (
								hotel?.photoList.map(
									(photo: any, index: any) => (
										<SwiperSlide key={index}>
											<img
												src={`https://api.mandarina.lv/${
													photo?.urlPhoto ||
													defaultPhoto
												}`}
												alt=''
												className='img img_sp'
											/>
										</SwiperSlide>
									)
								)
							) : (
								<SwiperSlide>
									<img
										src={defaultPhoto}
										alt=''
										className='img img_sp'
									/>
								</SwiperSlide>
							)}
						</Swiper>
					</div>

					<div className='name-star-wrapper name-star-wrapper_sp'>
						<h1 className='card_desc_name_palace card_desc_name_palace_sp'>
							{hotel?.name}
						</h1>
						<div className='hotel-stars'>
							{/* <RatingStar id='123' rating={hotel.rating / 100} /> */}
							{[...Array(Math.floor(hotel.rating / 100))].map(
								() => (
									<img src={hotelStar} alt='' />
								)
							)}
						</div>
					</div>
					<div
						className=''
						onClick={() => navigate(`/hotel/${hotel?.hotelCode}`)}
					>
						<p className='place-name'>{hotel?.location_en}</p>
						<p className='card_desc_type_eat card_description_body'>
							{t('the_duration_of_the_trip_from')} {hotel?.nights}{' '}
							{t('nights_register')}
						</p>
						<div className='card_desc_price_include card_desc_price_include_sp'>
							<p className='card_description_body'>
								{t('the_price_includes')}
							</p>
							<div
								className='svg-wrapper'
								//@ts-ignore
								title={t('the_price_includes')}
							>
								<svg
									className='svg'
									viewBox='0 0 22 22'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M9.625 0C10.4596 0 11.2159 0.495 11.55 1.2595L14.806 8.69825L19.2555 8.42188C19.9392 8.37934 20.6119 8.61009 21.1255 9.06339C21.6391 9.51668 21.9517 10.1554 21.9945 10.8391L22 11C22 11.6852 21.7278 12.3424 21.2433 12.8269C20.7588 13.3114 20.1016 13.5836 19.4164 13.5836L19.2555 13.5781L14.806 13.3004L11.5514 20.7391C11.3877 21.1139 11.1183 21.4327 10.7761 21.6567C10.434 21.8806 10.0339 22 9.625 22C9.30299 22 8.99418 21.8721 8.76648 21.6444C8.53879 21.4167 8.41088 21.1079 8.41088 20.7859L8.41363 20.7103L9.38025 12.9607L6.0005 12.749L4.433 15.884C4.34072 16.0691 4.19869 16.2247 4.02284 16.3335C3.84699 16.4424 3.64429 16.5 3.4375 16.5C3.25516 16.5 3.0803 16.4276 2.95136 16.2986C2.82243 16.1697 2.75 15.9948 2.75 15.8125V12.5455L1.28975 12.4561C0.940289 12.4344 0.612273 12.2802 0.372606 12.025C0.13294 11.7697 -0.000322875 11.4326 5.8747e-07 11.0825V10.9147C2.7232e-05 10.5649 0.133446 10.2281 0.373078 9.97317C0.612709 9.71821 0.940527 9.56419 1.28975 9.5425L2.75 9.45312V6.1875C2.75 6.00516 2.82243 5.8303 2.95136 5.70136C3.0803 5.57243 3.25516 5.5 3.4375 5.5C3.64411 5.50003 3.84665 5.55753 4.02245 5.66607C4.19826 5.77461 4.34041 5.92992 4.433 6.11462L5.99913 9.24963L9.38025 9.03788L8.4205 1.36538C8.39905 1.19452 8.41419 1.02106 8.46491 0.856498C8.51563 0.691941 8.60077 0.540056 8.71469 0.410925C8.82861 0.281794 8.96869 0.178371 9.12563 0.107524C9.28258 0.0366758 9.4528 2.3707e-05 9.625 0Z'
										fill='#546179'
									></path>
								</svg>
								<svg
									className='svg'
									width='25'
									height='22'
									viewBox='0 0 25 22'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M24.2617 2.75C24.6416 2.75 24.9492 2.44234 24.9492 2.0625V0.6875C24.9492 0.307656 24.6416 0 24.2617 0H0.886719C0.506875 0 0.199219 0.307656 0.199219 0.6875V2.0625C0.199219 2.44234 0.506875 2.75 0.886719 2.75H1.57336V19.25H0.886719C0.506875 19.25 0.199219 19.5577 0.199219 19.9375V21.3125C0.199219 21.6923 0.506875 22 0.886719 22H11.1992V18.5625C11.1992 18.1844 11.5086 17.875 11.8867 17.875H13.2617C13.6398 17.875 13.9492 18.1844 13.9492 18.5625V22H24.2617C24.6416 22 24.9492 21.6923 24.9492 21.3125V19.9375C24.9492 19.5577 24.6416 19.25 24.2617 19.25H23.5742V2.75H24.2617ZM11.1992 4.675C11.1992 4.4 11.4742 4.125 11.7492 4.125H13.3992C13.6742 4.125 13.9492 4.4 13.9492 4.675V6.325C13.9492 6.6 13.6742 6.875 13.3992 6.875H11.7492C11.4742 6.875 11.1992 6.6 11.1992 6.325V4.675ZM11.1992 8.8C11.1992 8.525 11.4742 8.25 11.7492 8.25H13.3992C13.6742 8.25 13.9492 8.525 13.9492 8.8V10.45C13.9492 10.725 13.6742 11 13.3992 11H11.7492C11.4742 11 11.1992 10.725 11.1992 10.45V8.8ZM5.69922 4.675C5.69922 4.4 5.97422 4.125 6.24922 4.125H7.89922C8.17422 4.125 8.44922 4.4 8.44922 4.675V6.325C8.44922 6.6 8.17422 6.875 7.89922 6.875H6.24922C5.97422 6.875 5.69922 6.6 5.69922 6.325V4.675ZM7.89922 11H6.24922C5.97422 11 5.69922 10.725 5.69922 10.45V8.8C5.69922 8.525 5.97422 8.25 6.24922 8.25H7.89922C8.17422 8.25 8.44922 8.525 8.44922 8.8V10.45C8.44922 10.725 8.17422 11 7.89922 11ZM8.44922 16.5C8.44922 14.2218 10.296 12.375 12.5742 12.375C14.8524 12.375 16.6992 14.2218 16.6992 16.5H8.44922ZM19.4492 10.45C19.4492 10.725 19.1742 11 18.8992 11H17.2492C16.9742 11 16.6992 10.725 16.6992 10.45V8.8C16.6992 8.525 16.9742 8.25 17.2492 8.25H18.8992C19.1742 8.25 19.4492 8.525 19.4492 8.8V10.45ZM19.4492 6.325C19.4492 6.6 19.1742 6.875 18.8992 6.875H17.2492C16.9742 6.875 16.6992 6.6 16.6992 6.325V4.675C16.6992 4.4 16.9742 4.125 17.2492 4.125H18.8992C19.1742 4.125 19.4492 4.4 19.4492 4.675V6.325Z'
										fill='#546179'
									></path>
								</svg>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
								>
									<path
										d='M15.03 4C16.11 4 17 4.88 17 6V17C17 18.13 16.11 19 15.03 19C15.03 19.58 14.56 20 14 20C13.5 20 13 19.58 13 19H7C7 19.58 6.5 20 6 20C5.44 20 4.97 19.58 4.97 19C3.89 19 3 18.13 3 17V6C3 4.88 3.89 4 4.97 4H7V1C7 0.42 7.46 0 8 0H12C12.54 0 13 0.42 13 1V4H15.03ZM11.5 4V1.5H8.5V4H11.5ZM6 7V16H7.5V7H6ZM12.5 7V16H14V7H12.5ZM9.25 7V16H10.75V7H9.25Z'
										fill='#546179'
									/>
								</svg>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='22'
									height='22'
									viewBox='0 0 22 22'
									fill='none'
								>
									<g clip-path='url(#clip0_259_639)'>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M17.93 4C18.3901 3.99992 18.844 4.10568 19.2567 4.30907C19.6694 4.51247 20.0298 4.80807 20.31 5.173L20.426 5.336L21.496 6.941C21.7829 7.37121 21.9538 7.86829 21.992 8.384L22 8.606V15C22.0002 15.5046 21.8096 15.9906 21.4665 16.3605C21.1234 16.7305 20.6532 16.9572 20.15 16.995L20 17H19.83C19.6284 17.5703 19.2592 18.0663 18.7707 18.4231C18.2823 18.7799 17.6974 18.9808 17.0929 18.9994C16.4883 19.018 15.8922 18.8534 15.3827 18.5273C14.8733 18.2012 14.4743 17.7288 14.238 17.172L14.17 17H7.829C7.62722 17.57 7.25797 18.0657 6.76964 18.4222C6.28131 18.7788 5.69667 18.9795 5.09232 18.9981C4.48797 19.0167 3.8921 18.8523 3.38278 18.5264C2.87345 18.2006 2.47444 17.7285 2.238 17.172L2.17 17H2C1.49542 17.0002 1.00943 16.8096 0.639452 16.4665C0.269471 16.1234 0.0428432 15.6532 0.005 15.15L4.66044e-09 15V7C-4.26217e-05 6.23479 0.292325 5.49849 0.817284 4.94174C1.34224 4.38499 2.06011 4.04989 2.824 4.005L3 4H17.93ZM17 15C16.7348 15 16.4804 15.1054 16.2929 15.2929C16.1054 15.4804 16 15.7348 16 16C16 16.2652 16.1054 16.5196 16.2929 16.7071C16.4804 16.8946 16.7348 17 17 17C17.2652 17 17.5196 16.8946 17.7071 16.7071C17.8946 16.5196 18 16.2652 18 16C18 15.7348 17.8946 15.4804 17.7071 15.2929C17.5196 15.1054 17.2652 15 17 15ZM5 15C4.73478 15 4.48043 15.1054 4.29289 15.2929C4.10536 15.4804 4 15.7348 4 16C4 16.2652 4.10536 16.5196 4.29289 16.7071C4.48043 16.8946 4.73478 17 5 17C5.26522 17 5.51957 16.8946 5.70711 16.7071C5.89464 16.5196 6 16.2652 6 16C6 15.7348 5.89464 15.4804 5.70711 15.2929C5.51957 15.1054 5.26522 15 5 15ZM17.93 6H17V10H20V8.606C20 8.40847 19.9416 8.21535 19.832 8.051L18.762 6.445C18.6707 6.30808 18.5469 6.19583 18.4018 6.11821C18.2566 6.04058 18.0946 5.99998 17.93 6ZM5 6H3C2.75507 6.00003 2.51866 6.08996 2.33563 6.25272C2.15259 6.41547 2.03566 6.63975 2.007 6.883L2 7V9H5V6ZM10 6H7V9H10V6ZM15 6H12V9H15V6Z'
											fill='#546179'
										/>
									</g>
									<defs>
										<clipPath id='clip0_259_639'>
											<rect
												width='22'
												height='22'
												fill='white'
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
						</div>
					</div>
					<div className='price_wrap'>
						<span className='width'>{t('c')}</span>
						<div className='price_bold_wrapper_sp'>
							<p className='price_bold price_bold_sp'>
								€ {hotel?.price.replace('.', ',')}
							</p>
							<div className='underline_sp'></div>
						</div>
					</div>
					<p className='price-description'>
						{t(
							'the_price_depends_on_the_date_of_departure_and_the_type_of_food'
						)}
					</p>
				</Link>
			</div>
		</div>
	)
}

export default SearchPageHotelCard
